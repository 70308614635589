import { TBuilding } from '../../../../types/building'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import Item3Form from './Item3Form'
import { useParams } from 'react-router-dom'
import { useAddDocumentMutation } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import dayjs from 'dayjs'
import { toastError } from '../../../../util/toastError'
import { LightButton } from '../../../common/LightButton'

const AddItem3Dialog = ({ building }: { building: TBuilding }) => {
  const id = 'add-item-3-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [addDocument] = useAddDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    const { date, signer_id, work_id } = values
    const body = {
      type: documentType.GWJ3Item,
      date,
      parent_id: journalId,
      data: {
        location: {
          place: values.place,
        },
      },
      works: [work_id],
      employees: [{ id: signer_id, type: 'signer' }],
    }

    setIsLoading(true)
    addDocument({ buildingId: building.id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        toast.success('Документ добавлен')
      })
      .catch(toastError)
      .finally(() => setIsLoading(false))
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить запись
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item3Form
          building={building}
          id={id}
          onSubmit={handleSubmit}
          values={{ date: dayjs().format('YYYY-MM-DD HH:mm:ss') }}
        />
      </FormDialog>
    </>
  )
}

export default AddItem3Dialog
