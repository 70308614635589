import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { LightButton } from '../../../common/LightButton'
import { documentType, IDocument } from '../../../../types/document'
import { CommonInfoForm } from './CommonInfoForm'
import { useAddEmployeeMutation, useEditDocumentMutation } from '../../../../store/document'
import { toastError } from '../../../../util/toastError'

interface IProps {
  document: IDocument
}

export const CommonInfoDialog = ({ document }: IProps) => {
  const id = 'common-info-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [editDocument] = useEditDocumentMutation()
  const [addEmployee] = useAddEmployeeMutation()

  const values = useMemo(() => {
    const values: any = {
      number: document.number,
      dateBegin: document.data?.worksPeriod.beginDate,
      dateEnd: document.data?.worksPeriod.endDate,
      volume: document.data?.journalVolume,
    }
    if (
      document?.employees?.developerRepresentative &&
      document?.employees?.developerRepresentative[0]
    ) {
      values.developerRepresentative = document?.employees?.developerRepresentative[0].employee_id
    }
    return values
  }, [document])

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)
    editDocument({
      buildingId: document.building_id,
      body: {
        type: documentType.GeneralWorkJournal,
        document_id: document.id,
        number: values.number,
        data: values,
      },
    })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        setOpen(false)
      })
      .catch(toastError)
      .finally(() => {
        setIsLoading(false)
      })

    addEmployee({
      buildingId: document.building_id,
      body: {
        document_id: document.id,
        employee_id: values.developerRepresentative,
        type: 'developerRepresentative',
      },
    })
  }

  return (
    <>
      <LightButton onClick={() => setOpen(true)}>Редактировать</LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Общие сведения'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <CommonInfoForm
          id={id}
          buildingId={document.building_id}
          onSubmit={handleSubmit}
          values={values}
        />
      </FormDialog>
    </>
  )
}
