import { Box } from '@mui/material'
import FormInput from '../../form/FormInput'
import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import Typography from '@mui/material/Typography'
import FormInputAddress from '../../form/FormInputAddress'
import FormSelect from '../../form/FormSelect'
import { TBuilding } from '../../../types/building'
import FormInputInn from '../../form/FormInputInn'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { toast } from 'react-toastify'
import { useCompaniesRolesList } from '../../../util/useCompaniesRolesList'

let registerSchema = object({
  inn: string()
    .min(1, 'Необходимо заполнить')
    .regex(/^\d{12}$/, 'Некорректный ИНН'),
  ogrn: string()
    .min(1, 'Необходимо заполнить')
    .regex(/^\d{15}$/, 'Некорректный ОГРНИП'),
  last_name: string().min(1, 'Необходимо заполнить'),
  first_name: string().min(1, 'Необходимо заполнить'),
  middle_name: string().min(1, 'Необходимо заполнить'),
  phone: string().refine(
    (value) => value === '' || /^(\+7|7|8)[()\d\s-]{7,20}$/.test(value),
    'Некорректный номер телефона'
  ), // regex(/^(\+7|7|8)[()\d\s-]{7,20}$/, 'Некорректный номер телефона'),
  email: string().refine(
    (value) => value === '' || /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,5}$/.test(value),
    'Некорректный E-mail'
  ),
  address: string().min(1, 'Необходимо заполнить'),
  sro_name: string(),
  sro_inn: string(),
  sro_ogrn: string(),
})

type Props = {
  id: string
  onSubmit: (values: any) => void
  values: Record<string, string>
  building: TBuilding
  isAdd: boolean
}

export const CompanyIEForm = ({ onSubmit, id, values = {}, building, isAdd = true }: Props) => {
  if (isAdd) {
    registerSchema = registerSchema.extend({
      role: string().min(1, 'Необходимо заполнить'),
    })
  }

  type RegisterInput = TypeOf<typeof registerSchema>

  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
    values: (values as any) || {},
  })

  const { setValue, trigger } = methods

  const roles = useCompaniesRolesList(building.id)

  const handleInnSelect = (data: any) => {
    if (!data) return

    if (data.data.type !== 'INDIVIDUAL') {
      toast.error('Компания не является ИП', { position: 'top-center' })
      return
    }

    setValue('last_name', data.data.fio.surname)
    setValue('first_name', data.data.fio.name)
    setValue('middle_name', data.data.fio.patronymic)
    setValue('ogrn', data.data.ogrn)
    setValue('address', data.data.address.unrestricted_value)
    trigger('address')
  }

  const handleSroInnSelect = (data: any) => {
    if (!data) return
    setValue('sro_name', data.data.name.short_with_opf)
    setValue('sro_ogrn', data.data.ogrn)
  }

  return (
    <FormProvider {...methods}>
      <Box
        component="form"
        id={id}
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
      >
        <Box sx={{ pt: 1 }}>
          {isAdd && <FormSelect name="role" label="Назначение" items={roles} />}
          <FormInputInn name="inn" label="ИНН" cType={'INDIVIDUAL'} onInnSelect={handleInnSelect} />
          <FormInput name="last_name" label="Фамилия" />
          <FormInput name="first_name" label="Имя" />
          <FormInput name="middle_name" label="Отчество" />
          <FormInput name="ogrn" label="ОГРНИП" />
          <FormInputAddress name="address" label="Адрес" />
          <FormInput name="phone" label="Телефон" />
          <FormInput name="email" label="E-mail" />
          <Typography variant={'h6'}>СРО:</Typography>
          <FormInputInn name="sro_inn" label="ИНН" onInnSelect={handleSroInnSelect} />
          <FormInput name="sro_name" label="Наименование" />
          <FormInput name="sro_ogrn" label="ОГРН" />
        </Box>
      </Box>
    </FormProvider>
  )
}
