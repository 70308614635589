import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { TAuth } from '../../types/auth'

export const authApi = createApi({
  reducerPath: 'auth',
  tagTypes: [],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
    validateStatus: (response) => response.ok,
  }),
  endpoints: (builder) => ({
    getBuildingAuth: builder.query<TAuth, string>({
      query: (buildingId) => ({
        url: `/buildings/${buildingId}/policy`,
        method: 'GET',
      }),
    }),
    getAuth: builder.query<TAuth, void>({
      query: () => ({
        url: `/policy`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetAuthQuery, useGetBuildingAuthQuery } = authApi
