import { BaseContainer } from '../../../components/common/Container'
import { Card, CardContent, Grid, Stack } from '@mui/material'
import { BuildingFullCard } from '../../../components/building/BuildingFullCard'
import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../../types/outlet-context'
import { useAuthorization } from '../../../auth'
import { BuildingDocuments } from '../../../components/building/BuildingDocuments'

const BuildingCard = () => {
  const { building } = useOutletContext<IOutletBuilding>()
  const authManager = useAuthorization()
  const isManage = authManager.vote('Building', 'manage')

  return (
    <BaseContainer>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Stack direction={'column'} spacing={2}>
            <BuildingFullCard building={building} />
            <BuildingDocuments building={building} type={'permission'} isManage={isManage} />
            <BuildingDocuments building={building} type={'pd_examination'} isManage={isManage} />
          </Stack>
        </Grid>
        <Grid item md={4} xs={12}>
          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <CardContent>
              <div>Map</div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </BaseContainer>
  )
}

export { BuildingCard }
