import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import { TBuilding } from '../../../../types/building'
import { parseError } from '../../../../api'
import { toast } from 'react-toastify'
import { useAddDocumentMutation } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { Record4AddForm } from './Record4AddForm'
import { LightButton } from '../../../common/LightButton'
import dayjs from 'dayjs'

interface IProps {
  building: TBuilding
  journalId: string | undefined
  onAdd: () => void
  eventId?: string
}

export const AddRecord4Dialog = ({ building, journalId, onAdd, eventId }: IProps) => {
  const id = 'add-control-event-form'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addDocument] = useAddDocumentMutation()

  const createDefectInfo = (values: any) => {
    const body = {
      parent_id: journalId,
      type: documentType.GWJ4DefectInfo,
      number: values.number,
      date: values.date,
      data: {
        description: values.description,
        structural_element: values.structural_element,
        defect_fixing_plan_date: values.defect_fixing_plan_date,
      },
      location: values.location,
      works: values.works,
      employees: [{ id: values.signer_id, type: 'signer' }],
    }
    return addDocument({ buildingId: building.id, body })
      .unwrap()
      .then((res) => res.id)
      .catch((e) => {
        setIsLoading(false)
      })
  }

  const handleSubmit = async (values: any, methods: any) => {
    setIsLoading(true)

    const body = {
      parent_id: journalId,
      type: documentType.GWJ4Item,
      date: values.date,
      data: {
        is_defect: values.is_defect,
        description: null,
      },
      employees: [{ id: values.signer_id, type: 'signer' }],
      over: [{ id: values.event_id, type: 'event' }] as any[],
    }

    if (values.is_defect) {
      const defectInfoId = await createDefectInfo(values)
      body.over.push({ id: defectInfoId, type: 'defectInfo' })
    } else {
      body.data.description = values.description
    }

    addDocument({ buildingId: building.id, body })
      .unwrap()
      .then((res) => {
        console.log(res)
        setIsLoading(false)
        setOpen(false)
        methods.reset()
        toast.success('Запись добавлена')
        onAdd()
      })
      .catch(async (e) => {
        const error = await parseError(e)
        toast.error(error)
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить запись
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Record4AddForm
          id={id}
          onSubmit={handleSubmit}
          building={building}
          values={{
            event_id: eventId,
            works: [],
            location: {},
            date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
          }}
        />
      </FormDialog>
    </>
  )
}
