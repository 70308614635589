import { FormHelperText, FormControl, InputProps } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

type IFormInputProps = {
  name: string
  label: string
  size?: 'small' | 'medium'
} & InputProps

const FormInputDateTime: FC<IFormInputProps> = ({
  name,
  label,
  size = 'medium',
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext()

  const errorMessage: string = (errors[name] ? errors[name]?.message : '') as string

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth margin={'dense'}>
          <DateTimePicker
            label={label}
            value={typeof value === 'string' && value ? dayjs(value) : null}
            onChange={(tDate) => {
              onChange(tDate?.isValid() ? tDate.format('YYYY-MM-DD HH:mm:ss') : '')
            }}
            closeOnSelect
            slotProps={{
              textField: { size: size },
              field: { clearable: true },
              openPickerButton: {
                color: 'primary',
              },
              inputAdornment: {
                position: 'start',
              },
            }}
          />
          <FormHelperText error={!!errors[name]}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInputDateTime
