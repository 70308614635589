import { TBuilding, TCompany, TEmployee } from '../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../common'
import {
  Badge,
  Box,
  Button,
  CardHeader,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../store/employees'
import { formatDate } from '../../../util/date'
import { StatusChip } from '../../common/StatusChip'
import { useMemo, useState } from 'react'
import { ItemView } from './ItemView'
import AddItem3Dialog from './AddItemDialog'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../store/lists'
import { toast } from 'react-toastify'
import { useGetStoreQuery } from '../../../store/store'
import { IStore } from '../../../types/store'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../store/document'
import { documentType, IDocument } from '../../../types/document'
import { useAuthorization } from '../../../auth'
import { useGetUserQuery } from '../../../store/user'
import { useGetCompaniesQuery } from '../../../store/companies'
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import { toastError } from '../../../util/toastError'
import { useChecked } from '../../../util/useChecked'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}
const supplierName = (companies: TCompany[], item: IDocument): string => {
  return companies.find((em) => em.id === item.companies.supplier[0].company_id)?.name || ''
}

const UsedProgress = (value: number, balance: number) => {
  const progress = (balance / value) * 100
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="caption" color="text.secondary">{`${balance}`}</Typography>
      </Box>
    </Box>
  )
}

export const ImcItemsList = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: { type: documentType.IMCItem, parent_id: journalId },
  })

  const { data: storesRes, refetch: refetchStore } = useGetStoreQuery(building.id)

  const {
    data: materials,
    isLoading: isMaterialsLoading,
    isSuccess: isWorksSuccess,
  } = useGetMaterialsQuery(building.id)

  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)

  const { data: units, refetch: refetchUnits } = useGetUnitsQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()

  const filteredItems = useMemo(() => {
    return data ? data.items.slice(offset, offset + limit) : []
  }, [data, offset, limit])

  const { onCheck, checked, isCheckedAll, isIndeterminate } = useChecked(
    filteredItems.map((i) => i.id)
  )

  const stores = useMemo((): Record<string, IStore> => {
    if (storesRes === undefined) return {}
    return storesRes.reduce((acc, store) => ({ ...acc, [store.imc_id]: store }), {})
  }, [storesRes])

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const currentStore = useMemo(() => {
    return stores && currentId && stores[currentId]
  }, [stores, currentId])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.IMCItem', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.IMCItem', 'manage', currentItem.owner_id === user.id)) ||
      false
    )
  }, [currentItem, user])

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || isMaterialsLoading || data === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const refetchAll = () => {
    refetch()
    refetchStore()
    refetchUnits()
  }

  const removeItemHandler = (id: string | undefined) => {
    if (id === undefined) return
    removeItem({
      buildingId: building.id,
      body: {
        document_id: id,
        type: documentType.IMCItem,
      },
    })
      .unwrap()
      .then(() => {
        toast.success('Запись удалена')
        setCurrentId(undefined)
        setDrawerOpen(false)
        refetchAll()
      })
      .catch(toastError)
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            action={isCreate && <AddItem3Dialog building={building} onAdd={refetchAll} />}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '50px' }}>
                  <Checkbox
                    checked={isCheckedAll}
                    value={'all'}
                    onChange={onCheck}
                    indeterminate={isIndeterminate}
                  />
                </TableCell>
                <TableCell>
                  <div>Материал</div>
                  <Typography variant={'caption'}>Поставщик</Typography>
                </TableCell>
                <TableCell>
                  <div>Объем/кол-во</div>
                  <Typography variant={'caption'}>Остаток</Typography>
                </TableCell>
                <TableCell>Дата поступления</TableCell>
                <TableCell>ДПК</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell>Статус документа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => {
                const store = stores[item.id]
                return (
                  <TableRow key={item.id} hover sx={{ cursor: 'pointer' }}>
                    <TableCell>
                      <Checkbox
                        checked={checked.includes(item.id)}
                        value={item.id}
                        onChange={onCheck}
                      />
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <Box>
                        <Typography variant={'body1'}>
                          {materials && store && materials[store.material_id].name}
                        </Typography>
                      </Box>
                      <Typography variant={'caption'} color={'text.disabled'}>
                        {companies && supplierName(companies, item)}
                      </Typography>
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {store &&
                        <>
                          <Box>
                            <Typography variant={'body1'} component={'span'}>
                              {store.value}
                            </Typography>
                            <Typography variant={'caption'} color={'text.disabled'}>
                              {'\u00A0'}
                              {units && units[store.unit_id].name}
                            </Typography>
                          </Box>
                          {UsedProgress(store.value, store.balance)}
                        </>
                      }
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {formatDate(item.date)}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {item.external_documents.dpk && (
                        <Badge badgeContent={item.external_documents.dpk.length} color={'error'}>
                          <TaskOutlinedIcon />
                        </Badge>
                      )}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      {employees && signerName(employees, item)}
                    </TableCell>
                    <TableCell onClick={() => viewItem(item.id)}>
                      <StatusChip status={item.status} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          <>
            {isManage && (
              <Button
                variant={'outlined'}
                color={'error'}
                onClick={() => removeItemHandler(currentId)}
              >
                Удалить
              </Button>
            )}
          </>
        }
      >
        {currentItem && currentStore && (
          <ItemView
            building={building}
            item={currentItem}
            store={currentStore}
            onChange={refetchAll}
            isManage={isManage}
          />
        )}
      </RightPanel>
    </>
  )
}
