import {
  FormHelperText,
  FormControl,
  InputProps,
  OutlinedInput,
  InputLabel,
  SxProps,
  Theme,
} from '@mui/material'
import React, { ReactNode, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import MenuIcon from '@mui/icons-material/Menu'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { TExternalDocument, TExternalDocumentType } from '../../types/external-document'
import { useGetExternalDocumentsQuery } from '../../store/externalDocuments'
import { useOutletContext } from 'react-router-dom'
import { extDocName } from '../../util/extDocs'
import ExtDocViewDialog from '../ExternalDocuments/ExtDocViewDialog'
import ExtDocListDialog from '../ExternalDocuments/ExtDocListDialog'
import { IOutletBuilding } from '../../types/outlet-context'

type IFormInputProps = {
  name: string
  label: string
  hint?: ReactNode
  size?: 'small' | 'medium'
  sx?: SxProps<Theme>
  docType?: TExternalDocumentType
} & InputProps

const FormInputExtDocument = ({
  name,
  label,
  hint,
  size,
  docType,
  ...otherProps
}: IFormInputProps) => {
  const [doc, setDoc] = useState<TExternalDocument | null>()
  const [openView, setOpenView] = useState(false)
  const [openList, setOpenList] = useState(false)
  const [docName, setDocName] = useState('')

  const { building } = useOutletContext<IOutletBuilding>()

  const { data: docs, isLoading } = useGetExternalDocumentsQuery({ buildingId: building.id })

  const {
    control,
    formState: { errors },
    getValues,
    setValue,
  } = useFormContext()

  const errorMessage: string = (errors[name] ? errors[name]?.message : '') as string

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  useEffect(() => {
    const docId = getValues()[name]
    if (docId && docs) {
      const doc = docs.items.find((doc) => doc.id === docId)
      if (doc) {
        setDoc(doc)
        setDocName(extDocName(doc))
      }
    }
  }, [docs, getValues])

  const clear = () => {
    setDoc(null)
    setDocName('')
    setValue(name, '')
  }

  const endAdornment = (
    <>
      {doc && (
        <IconButton onClick={clear}>
          <ClearIcon />
        </IconButton>
      )}
      <IconButton onClick={() => setOpenList(true)} title={'Выбрать'}>
        <MenuIcon />
      </IconButton>
      {doc && (
        <IconButton onClick={() => setOpenView(true)} title={'Посмотреть'}>
          <FindInPageIcon />
        </IconButton>
      )}
    </>
  )

  const handleSelect = (id: string) => {
    setValue(name, id)
    setOpenList(false)
    if (id && docs) {
      const doc = docs.items.find((doc) => doc.id === id)
      if (doc) {
        setDoc(doc)
        setDocName(extDocName(doc))
      }
    }
  }

  return (
    <>
      <Controller
        control={control}
        defaultValue=""
        name={name}
        render={({ field: { onChange, value, ...other } }) => (
          <FormControl fullWidth margin={'dense'} size={size}>
            <InputLabel>{label}</InputLabel>
            <input type="hidden" onChange={onChange} value={value} {...other} />
            <OutlinedInput
              error={!!errors[name]}
              label={label}
              endAdornment={endAdornment}
              readOnly
              value={docName}
              onClick={() => setOpenList(true)}
            />
            {hintEl}
            <FormHelperText error={!!errors[name]}>{errorMessage}</FormHelperText>
          </FormControl>
        )}
      />
      {doc && (
        <ExtDocViewDialog document={doc} open={openView} onClose={() => setOpenView(false)} />
      )}
      {docs && (
        <ExtDocListDialog
          documents={docs.items}
          open={openList}
          docType={docType}
          onAdd={(id) => {
            handleSelect(id)
            setOpenView(true)
          }}
          onClose={() => setOpenList(false)}
          onDocumentClick={handleSelect}
          variant={'select'}
        />
      )}
    </>
  )
}

export default FormInputExtDocument
