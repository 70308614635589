import { TBuilding } from '../../../types/building'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { FormDialog } from '../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import ItemForm from './ItemForm'
import { LightButton } from '../../common/LightButton'
import { documentType, IDocument } from '../../../types/document'
import { IStore } from '../../../types/store'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../store/lists'
import { useEditDocumentMutation } from '../../../store/document'

type Props = {
  item: IDocument
  store: IStore
  building: TBuilding
  onAdd: () => void
}

const EditItemDialog = ({ item, store, building, onAdd }: Props) => {
  const id = 'edit-item-imc-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const { data: units } = useGetUnitsQuery(building.id)
  const { data: materials } = useGetMaterialsQuery(building.id)

  const [editDocument] = useEditDocumentMutation()

  const values = {
    signer_id: item.employees.signer[0].employee_id,
    supplier_id: item.companies.supplier[0].company_id,
    date: item.date,
    data: {
      material: materials && materials[store.material_id].name,
      unit: units && units[store.unit_id].name,
      amount: store.value,
      quality_conclusion: item?.data?.quality_conclusion,
    },
  }

  const handleSubmit = (values: any, methods: any) => {
    const body = {
      ...values,
      type: documentType.IMCItem,
      document_id: item.id,
      employees: [{ id: values.signer_id, type: 'signer' }],
      companies: [{ id: values.supplier_id, type: 'supplier' }],
    }

    setIsLoading(true)

    editDocument({ buildingId: building.id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ добавлен')
        onAdd()
      })
      .catch(() => {
        toast.error('Произошла ошибка')
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton onClick={() => setOpen(true)}>Редактировать</LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Редактировать запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <ItemForm building={building} id={id} onSubmit={handleSubmit} values={values} />
      </FormDialog>
    </>
  )
}

export { EditItemDialog }
