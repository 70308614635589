import {
  companyRoles,
  TBuilding,
  TCompany,
  TCompanyIndividualEntrepreneur,
  TCompanyLegal,
} from '../../types/building'
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  MenuItem,
  TableContainer,
  Typography,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import Diversity3Icon from '@mui/icons-material/Diversity3'
import Box from '@mui/material/Box'
import { LegalCard } from './LegalCard'
import { IECard } from './IECard'
import React, { useState } from 'react'
import { CompanyDialogForm } from '../building/form/CompanyDialogForm'
import {
  useEditCompanyIEMutation,
  useEditCompanyLegalMutation,
  useRemoveCompanyMutation,
} from '../../store/companies'
import { MoreVertMenu } from '../menu'
import { useAuthorization } from '../../auth'
import { toastError } from '../../util/toastError'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export const CompanyCard = ({ building, company }: { building: TBuilding; company: TCompany }) => {
  const authManager = useAuthorization()

  const [open, setOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const [editLegal] = useEditCompanyLegalMutation()
  const [editIe] = useEditCompanyIEMutation()
  const [removeCompany] = useRemoveCompanyMutation()
  const navigate = useNavigate()

  const isActive = company.status === 'active'
  const isLegal = company.type === 'legal'

  const isManage = authManager.vote('Company', 'manage')

  let values = {
    ...company,
    address: company.address.string_address,
    sro_name: company.sro?.name,
    sro_inn: company.sro?.inn,
    sro_ogrn: company.sro?.ogrn,
  } as any

  if (company.type === 'individual_entrepreneur' && company.individual_name) {
    values = {
      ...values,
      first_name: company.individual_name.first_name,
      last_name: company.individual_name.last_name,
      middle_name: company.individual_name.middle_name,
    }
  }

  const handleEdit = (values: any) => {
    const edit = company.type === 'legal' ? editLegal : editIe
    edit({
      buildingId: building.id,
      body: { ...values, company_id: company.id },
    })
      .unwrap()
      .then((res) => {
        setOpen(false)
      })
      .catch(toastError)
  }

  const handleRemove = () => {
    setOpen(false)
    removeCompany({ buildingId: building.id, body: { company_id: company.id } })
      .unwrap()
      .then((res) => {
        toast.success('Компания удалена')
        navigate(`../../companies`)
      })
      .catch(toastError)
  }

  return (
    <>
      <TableContainer component={Card} sx={{ mb: 2 }}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: isActive ? green[500] : red[500] }}>
              <Diversity3Icon />
            </Avatar>
          }
          action={
            isManage && (
              <MoreVertMenu
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onOpen={() => setMenuOpen(true)}
              >
                <MenuItem
                  onClick={() => {
                    setOpen(true)
                    setMenuOpen(false)
                  }}
                >
                  Редактировать
                </MenuItem>
                <MenuItem onClick={handleRemove}>Удалить</MenuItem>
              </MoreVertMenu>
            )
          }
          title={isActive ? 'Активна' : 'Не активна'}
        />
        <CardContent>
          <Box sx={{ mb: 3 }}>
            <Typography variant={'h6'}>{company.name}</Typography>
            <Typography variant={'overline'}>{companyRoles[company.role]}</Typography>
          </Box>
          {isLegal && <LegalCard company={company as TCompanyLegal} />}
          {!isLegal && <IECard company={company as TCompanyIndividualEntrepreneur} />}
        </CardContent>
      </TableContainer>
      {isManage && (
        <CompanyDialogForm
          type={'edit'}
          open={open}
          onClose={() => setOpen(false)}
          values={values}
          onSubmit={handleEdit}
          building={building}
        />
      )}
    </>
  )
}
