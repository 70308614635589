import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { addAuthorization } from '../../util/http/addAuthorization'
import { IBuildingCard } from './types'
import { TBuilding, TBuildingUser } from '../../types/building'
import { TProfile } from '../../types/Profile'

export const buildingsApi = createApi({
  reducerPath: 'buildings',
  tagTypes: ['Buildings', 'Building', 'Profile', 'Employees', 'Users'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/',
    prepareHeaders: addAuthorization,
  }),
  endpoints: (builder) => ({
    getBuildings: builder.query<{ items: IBuildingCard[]; metadata: any }, any | void>({
      query: (params) => ({
        url: '/buildings',
        method: 'GET',
        params: params,
      }),
      providesTags: (result) => ['Buildings'],
    }),
    addBuilding: builder.mutation({
      query: (data) => ({
        url: '/buildings',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result) => ['Buildings'],
    }),
    getBuilding: builder.query<TBuilding, string>({
      query: (id) => ({
        url: `/buildings/${id}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: (result, error, arg) => [{ type: 'Building', id: arg }],
    }),

    editBuilding: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result) => ['Building'],
    }),
    attachPostalAddress: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/attach-postal-address`,
        method: 'POST',
        body: body,
      }),
      async onQueryStarted({ buildingId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          buildingsApi.util.updateQueryData('getBuilding', buildingId, (draft) => {
            draft.postal_address = body
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      // invalidatesTags: result => ['Building']
    }),
    attachConstructionSiteAddress: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/attach-construction-site-address`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ buildingId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          buildingsApi.util.updateQueryData('getBuilding', buildingId, (draft) => {
            draft.construction_site_address = body
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      //  invalidatesTags: result => ['Building']
    }),
    getProfiles: builder.query<Record<string, TProfile>, string>({
      query: (id) => ({
        url: `/buildings/${id}/profiles`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'Profile', id: arg }],
      transformResponse: (response: TProfile[], meta, arg) => {
        return response.reduce((u, profile) => ({ ...u, [profile.id]: profile }), {})
      },
    }),
    getBuildingUsers: builder.query<TBuildingUser[], string>({
      query: (id) => ({
        url: `/buildings/${id}/users`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg }],
    }),
    attachBuildingUser: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/attach-building-user`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.buildingId }],
    }),
    changeBuildingUserRole: builder.mutation({
      query: ({ buildingId, body }) => ({
        url: `/buildings/${buildingId}/change-building-user-role`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ buildingId, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          buildingsApi.util.updateQueryData('getBuildingUsers', buildingId, (draft) => {
            const user = draft.find((user) => user.user_id === body.user_id)
            if (user) {
              user.role = body.role
            }
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      // invalidatesTags: (result, error, arg) => [{type: 'Building', id: arg.buildingId}]
    }),
    removeBuildingUser: builder.mutation({
      query: ({ buildingId, userId }) => ({
        url: `/buildings/${buildingId}/remove-building-user`,
        method: 'POST',
        body: { user_id: userId },
      }),
      async onQueryStarted({ buildingId, userId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          buildingsApi.util.updateQueryData('getBuildingUsers', buildingId, (draft) =>
            draft.filter((user) => user.user_id !== userId)
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      // invalidatesTags: (result, error, arg) => [{type: 'Building', id: arg.buildingId}]
    }),
  }),
})

export const {
  useGetBuildingsQuery,
  useGetBuildingQuery,
  useAddBuildingMutation,
  useEditBuildingMutation,
  useAttachPostalAddressMutation,
  useAttachConstructionSiteAddressMutation,
  useGetProfilesQuery,
  useAttachBuildingUserMutation,
  useChangeBuildingUserRoleMutation,
  useRemoveBuildingUserMutation,
  useGetBuildingUsersQuery,
} = buildingsApi
