import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import React, { useState } from 'react'
import { CompanyLegalForm } from './CompanyLegalForm'
import { TBuilding, TCompanyType } from '../../../types/building'
import { CompanyIEForm } from './CompanyIEForm'

type Props = {
  type: 'add' | 'edit'
  open: boolean
  onClose: () => void
  onSubmit: (values: any, type: TCompanyType) => void
  values?: Record<string, string>
  building: TBuilding
}

export const CompanyDialogForm = ({
  type,
  open,
  onClose,
  onSubmit,
  values = { type: 'legal' },
  building,
}: Props) => {
  const [companyType, setCompanyType] = useState<TCompanyType>(
    (values.type || 'legal') as TCompanyType
  )

  const handleTypeChange = (event: React.MouseEvent<HTMLElement>, value: TCompanyType) => {
    setCompanyType(value)
  }

  const handleSubmit = (values: any) => {
    onSubmit(values, companyType)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
        <DialogTitle>{type === 'add' ? 'Добавить компанию' : 'Редактировать компанию'}</DialogTitle>
        <DialogContent>
          {type === 'add' && (
            <ToggleButtonGroup
              value={companyType}
              exclusive
              onChange={handleTypeChange}
              sx={{ mb: 2 }}
            >
              <ToggleButton value={'legal'}>Юридическое лицо</ToggleButton>
              <ToggleButton value={'individual_entrepreneur'}>ИП</ToggleButton>
            </ToggleButtonGroup>
          )}
          {companyType === 'legal' && (
            <CompanyLegalForm
              id={type + '-legal-company'}
              onSubmit={handleSubmit}
              values={values}
              building={building}
              isAdd={type === 'add'}
            />
          )}
          {companyType === 'individual_entrepreneur' && (
            <CompanyIEForm
              id={type + '-individual_entrepreneur-company'}
              onSubmit={handleSubmit}
              values={values}
              building={building}
              isAdd={type === 'add'}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            type={'submit'}
            form={type + '-' + companyType + '-company'}
            variant={'contained'}
          >
            Отправить
          </Button>
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
