import ExtDocsList from './ExtDocsList'
import * as React from 'react'
import { TExternalDocument, TExternalDocumentType } from '../../types/external-document'
import { useEffect, useState } from 'react'
import { useGetUserQuery } from '../../store/user'
import { SkidPagination, useSkidPagination } from '../common'
import { Box, Chip, Collapse, Divider, IconButton } from '@mui/material'
import ExtDocsFilter from './ExtDocsFilter'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import TuneIcon from '@mui/icons-material/Tune'

type Props = {
  documents: TExternalDocument[]
  onRowClick: (id: string) => void
  variant: 'full' | 'select'
  docType?: TExternalDocumentType
}

const ExtDocsListFilter = ({ documents, onRowClick, variant, docType }: Props) => {
  const { data: user } = useGetUserQuery()
  const [filteredDocuments, setFilteredDocuments] = useState<TExternalDocument[]>([])
  const [filterOpen, setFilterOpen] = useState(false)
  const [filterValues, setFilterValues] = useState<Record<string, string>>(
    docType ? { type: docType } : {}
  )
  const { pages, page, offset, limit, onPageChange, setTotal, reset } = useSkidPagination(20)

  useEffect(() => {
    if (documents && user) {
      let docs: TExternalDocument[] = documents.filter((doc) => doc.owner_id === user.id)

      if (Object.keys(filterValues).length > 0) {
        const typeFilter: (doc: TExternalDocument) => boolean = filterValues?.type.trim()
          ? (doc) => doc.type === filterValues.type.trim()
          : (doc) => true
        const nameFilter: (doc: TExternalDocument) => boolean = filterValues.name
          ? (doc) => new RegExp(filterValues.name, 'i').test(doc.name)
          : (doc) => true
        const numberFilter: (doc: TExternalDocument) => boolean = filterValues.number
          ? (doc) => new RegExp(filterValues.number, 'i').test(doc.number)
          : (doc) => true
        const fromDateFilter: (doc: TExternalDocument) => boolean = filterValues.dateFrom
          ? (doc) => new Date(doc.date).getTime() >= new Date(filterValues.dateFrom).getTime()
          : (doc) => true
        const toDateFilter: (doc: TExternalDocument) => boolean = filterValues.dateTo
          ? (doc) =>
              new Date(doc.date) <= new Date(filterValues.dateTo.substring(0, 10) + ' 23:59:59')
          : (doc) => true

        docs = docs
          .filter(typeFilter)
          .filter(nameFilter)
          .filter(numberFilter)
          .filter(fromDateFilter)
          .filter(toDateFilter)

        reset()
      }
      setTotal(docs.length)
      setFilteredDocuments(docs)
    }
  }, [filterValues, documents, user])

  const onFilterChange = (values: any, name: string | undefined, type: string | undefined) => {
    setFilterValues(values)
  }

  return (
    <>
      <Box sx={{ p: 2, pt: 0 }}>
        <Collapse in={filterOpen}>
          <ExtDocsFilter onChange={onFilterChange} values={filterValues} />
        </Collapse>
        <Divider sx={{ mt: 2 }}>
          {filterOpen && (
            <IconButton color={'primary'} onClick={() => setFilterOpen(!filterOpen)}>
              <KeyboardDoubleArrowUpIcon />
            </IconButton>
          )}
          {!filterOpen && (
            <Chip label="Фильтр" onClick={() => setFilterOpen(!filterOpen)} icon={<TuneIcon />} />
          )}
        </Divider>
      </Box>
      <ExtDocsList
        items={filteredDocuments.slice(offset, offset + limit)}
        variant={variant}
        onRowClick={onRowClick}
      />
      <SkidPagination page={page} pages={pages} onChange={onPageChange} />
    </>
  )
}

export default ExtDocsListFilter
