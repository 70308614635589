import { custom, object, TypeOf } from 'zod'
import { TPrdDocument } from '../../types/prd'
import { useGetUserQuery } from '../../store/user'
import { ChangeEvent, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { toast } from 'react-toastify'
import { download } from '../../util/download'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CloseIcon from '@mui/icons-material/Close'
import { formatBytes } from '../../util/formatBytes'
import * as React from 'react'
import { useAttachFileMutation, useDetachFileMutation } from '../../store/prd'
import { MuiFileInput } from 'mui-file-input'

const fileUploadSchema = object({
  file: custom<File>(),
})

type IUploadFile = TypeOf<typeof fileUploadSchema>

type Props = {
  document: TPrdDocument
}
const PrdDocumentFile = ({ document }: Props) => {
  const { data: user } = useGetUserQuery()

  const isOwner = user && user.id === document.user_id

  const [file, setFile] = useState<File | null>(null)

  const methods = useForm<IUploadFile>({
    resolver: zodResolver(fileUploadSchema),
  })

  const [attachFile] = useAttachFileMutation()
  const [detachFile] = useDetachFileMutation()

  const onSubmitHandler: SubmitHandler<IUploadFile> = (values) => {
    if (!file) return
    const formData = new FormData()
    formData.append('file', file)
    formData.append('group_id', document.group_id)
    formData.append('document_id', document.id)
    attachFile({ buildingId: document.building_id, body: formData })
      .unwrap()
      .then((res) => {
        setFile(null)
      })
    setFile(null)
    methods.reset()
  }

  const handleDetachFile = (e: any) => {
    e.stopPropagation()
    detachFile({
      buildingId: document.building_id,
      body: {
        group_id: document.group_id,
        document_id: document.id,
      },
    })
      .unwrap()
      .then(() => {
        toast.success('Файл откреплен')
      })
      .catch((e) => {
        toast.error('Ошибка')
      })
  }

  const handleDownload = (file: any) => {
    const url = `/api/v1/buildings/${document.building_id}/files/${file.id}`
    download(url, file.name, file.media_type)
  }

  const handleChange = (newFile: File | null) => {
    setFile(newFile)
  }

  return (
    <>
      {document.file && (
        <Box>
          <Typography variant={'caption'}>Прикрепленный файл</Typography>
          <Card variant={'outlined'}>
            <CardHeader
              onClick={() => handleDownload(document.file)}
              sx={{ cursor: 'pointer' }}
              avatar={
                <Avatar sx={{ bgcolor: green[500] }}>
                  <AttachFileIcon />
                </Avatar>
              }
              action={
                isOwner && (
                  <IconButton onClick={handleDetachFile} color={'error'} title={'Открепить'}>
                    <CloseIcon />
                  </IconButton>
                )
              }
              title={document.file.name}
              subheader={formatBytes(document.file.size)}
            />
          </Card>
        </Box>
      )}
      {isOwner && (
        <FormProvider {...methods}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={methods.handleSubmit(onSubmitHandler)}
          >
            <Stack direction={'row'} alignItems={'center'} sx={{ mt: 2 }} spacing={0.5}>
              <MuiFileInput
                value={file}
                onChange={handleChange}
                size={'small'}
                InputProps={{
                  inputProps: {
                    accept: 'application/pdf',
                  },
                  startAdornment: <AttachFileIcon />,
                }}
                label={document.file ? 'Заменить файл' : 'Прикрепить файл'}
                placeholder={'Выбрать файл (PDF)...'}
                clearIconButtonProps={{
                  title: 'Remove',
                  children: <CloseIcon fontSize="small" />,
                }}
              />
              {file && <Button type={'submit'}>Отправить</Button>}
            </Stack>
            <FormHelperText error={!!methods.formState.errors.file}>
              {methods.formState.errors.file?.message}
            </FormHelperText>
          </Box>
        </FormProvider>
      )}
    </>
  )
}

export { PrdDocumentFile }
