import { coerce, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useMemo } from 'react'
import { Box, Stack } from '@mui/material'
import FormInput from '../form/FormInput'
import { useGetCompaniesQuery } from '../../store/companies'
import { TBuilding } from '../../types/building'
import FormSelect from '../form/FormSelect'
import { companiesList } from '../../util/companies'

const schema = {
  name: string().min(1, 'Необходимо заполнить'),
  code: string().min(1, 'Необходимо заполнить'),
  lists_count: coerce
    .number({
      invalid_type_error: 'Значение должно быть целым положительным числом',
      required_error: 'Значение должно быть целым положительным числом',
    })
    .int()
    .positive('Минимум 1'),
  designer_id: string().min(1, 'Необходимо заполнить'),
}
const registerSchema = object(schema)

interface Props {
  building: TBuilding
  values?: any
  onSubmit: (values: TypeOf<typeof registerSchema>, methods: any) => void
  id?: string
}

const PrdDocumentForm = ({ building, values, onSubmit, id }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {
      type: 'default',
    },
  })

  const { data: companies } = useGetCompaniesQuery(building.id)

  const designerItems = useMemo(() => {
    return companies
      ? companiesList(companies, ['designer'])
      : []
  }, [companies])

  const {
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods

  const hs = handleSubmit((values: any) => {
    onSubmit(values, methods)
  })

  const onFormSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    return hs(e)
  }

  return (
    <FormProvider {...methods}>
      <Box id={id} component="form" onSubmit={onFormSubmit} noValidate autoComplete="off">
        <FormInput name="name" label="Наименование" />
        <Stack direction={'row'} spacing={2}>
          <FormInput name="code" label="Шифр" />
          <FormInput name="lists_count" label="Количество листов" />
        </Stack>
        <FormSelect
          name="designer_id"
          label="Автор"
          hint={'Лицо, осуществляющее подготовку проектной документации'}
          items={designerItems}
        />
      </Box>
    </FormProvider>
  )
}

export default PrdDocumentForm
