import { TBuilding } from '../../../../types/building'
import { Stack } from '@mui/material'
import * as React from 'react'
import { CommonInfo } from './CommonInfo'
import { Representatives } from './Representatives'
import { Sar } from './Sar'
import { BuildingDocuments } from '../../../building/BuildingDocuments'

export const TitleListComponent = ({ building }: { building: TBuilding }) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} alignItems="stretch">
      <Stack direction={'column'} spacing={2}>
        <CommonInfo building={building} />
        <BuildingDocuments building={building} type={'permission'} isManage={false} />
        <BuildingDocuments building={building} type={'pd_examination'} isManage={false} />
        <Sar building={building} />
      </Stack>
      <Representatives building={building} />
    </Stack>
  )
}
