import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useAddCertificateMutation, useGetProfileQuery } from '../../store/profile'
import { certList } from '../../util/signature/certList'
import FormSelect from '../form/FormSelect'
import { Add } from '@mui/icons-material'
import { formatDate } from '../../util/date'
import { IProfile } from '../../store/profile/types'
import { TBuilding } from '../../types/building'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { parseError } from '../../api'

const isCertValid = (cert: any, profile: IProfile) => {
  return (
    (cert.subject.O && new Date(cert.period.from) < new Date('2023-09-01T00:00:00')) ||
    cert.inn === profile.inn
  )
}

const registerSchema = object({
  thumbPrint: string().min(1, 'Необходимо выбрать'),
})

export type RegisterInput = TypeOf<typeof registerSchema>

const ProfileDialogForm = () => {
  const methods = useForm<RegisterInput>({
    resolver: zodResolver(registerSchema),
    defaultValues: {},
  })

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [certs, setCerts] = useState<any[]>([])
  const [certsIsLoading, setCertsIsLoading] = useState(false)
  const [certsItems, setCertsItems] = useState<any[]>([])

  const { data: profile } = useGetProfileQuery()
  const [addCertificate] = useAddCertificateMutation()

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods

  useEffect(() => {
    if (!profile) return
    certList().then((certs) => {
      setCerts(certs)
      // fixme: на первое время можно пользоваться сертификатом организации
      //  выпущенным до 01.09.2023 до окончания срока серта
      //  в дальнейшем можно включить фильтр только по инн профиля
      setCertsItems(
        certs
          .filter((c) => isCertValid(c, profile))
          .map((cert) => {
            return {
              label: cert.name,
              description: `${cert.issuer.CN} до ${formatDate(cert.period.to)}`,
              value: cert.thumbprint,
            }
          })
      )
    })
  }, [profile])

  const onSubmitHandler: SubmitHandler<RegisterInput> = (values) => {
    console.log(certs)
    const cert = certs.find((c) => c.thumbprint === values.thumbPrint)
    console.log(cert, profile)

    const data = {
      name: cert.name,
      issuer_name: cert.issuerName,
      thumbprint: cert.thumbprint,
      serial_number: cert.serialNumber,
      inn: cert.inn,
      from: dayjs(cert.period.from).format('YYYY-MM-DD HH:mm:ss'),
      to: dayjs(cert.period.to).format('YYYY-MM-DD HH:mm:ss'),
    }

    console.log(data)

    setIsLoading(true)
    addCertificate(data)
      .then((res: any) => {
        if (res.error) {
          throw res.error
        }
        toast.success('Сертификат успешно сохранен', {
          position: 'top-center',
        })
        setIsLoading(false)
        setOpen(false)
        reset()
      })
      .catch(async (error) => {
        const text = await parseError(error)
        toast.error(text, {
          position: 'top-center',
        })
        setIsLoading(false)
      })
  }

  return (
    <>
      <Button startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить сертификат
      </Button>
      <Dialog open={open} disableEscapeKeyDown fullWidth>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
          >
            <DialogTitle>Добавить сертификат</DialogTitle>
            <DialogContent>
              {certsItems.length > 0 && (
                <FormSelect name="thumbPrint" label="Сертификат" items={certsItems} />
              )}
              {certsItems.length === 0 && (
                <Alert severity="error">
                  <AlertTitle>Сертификаты не найдены</AlertTitle>
                  Возможно подходящие сертификаты отсутствуют на устройстве. Сертификат быть выдан
                  на ваше имя и содержать ваш ИНН.
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <LoadingButton
                variant="contained"
                sx={{ mt: 1 }}
                type="submit"
                disableElevation
                loading={isLoading}
              >
                Сохранить
              </LoadingButton>
              <Button onClick={() => setOpen(false)}>Закрыть</Button>
            </DialogActions>
          </Box>
        </FormProvider>
      </Dialog>
    </>
  )
}

export default ProfileDialogForm
