import { TBuilding } from '../../../../types/building'
import { Card, CardContent, CardHeader, Stack } from '@mui/material'
import { DataRow } from '../../../common/DataRow'
import { formatDate } from '../../../../util/date'
import { AddSarDialog } from './AddSarDialog'
import * as React from 'react'
import { useAuthorization } from '../../../../auth'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { useMemo } from 'react'
import { useGetDocumentsQuery } from '../../../../store/document'
import { documentType } from '../../../../types/document'
import { SignDocumentButton } from '../../../document/SignDocumentButton'

type Props = {
  building: TBuilding
}
const Sar = ({ building }: Props) => {
  const authManager = useAuthorization()

  const { journalId } = useParams()

  const { data: sarRes, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      parent_id: journalId,
      type: documentType.SupervisoryAuthorityRegistry,
    },
  })

  const sar = sarRes ? sarRes.items[0] : undefined

  const { data: employees } = useGetEmployeesQuery(building.id)

  const isManage = authManager.vote('Gwj', 'manage')

  const sarEmployee = useMemo(() => {
    if (!employees || !sar) return undefined
    return employees.find((e) => sar.employees.signer[0]?.employee_id === e.id)
  }, [employees, sar]) // = getSarEmployee(employees, sar)

  return (
    <Card>
      <CardHeader title="Регистрационная надпись органа государственного строительного надзора" />
      <CardContent>
        <Stack direction="column" spacing={1}>
          {sar && employees && (
            <>
              <DataRow
                label={
                  'Идентификационный номер журнала в системе Государственного строительного надзора (Номер дела/регистрационный номер)'
                }
                text={sar.number}
              />
              <DataRow label={'Дата регистрации'} text={formatDate(sar.date)} />
              {sarEmployee && (
                <DataRow
                  label={'Представитель Госстройнадзора, внёсший регистрационную запись'}
                  text={sarEmployee.profile.name.full_name}
                  description={sarEmployee.position}
                />
              )}
            </>
          )}
        </Stack>
      </CardContent>
      <CardContent>
        <Stack spacing={2} direction={'row'}>
          {(!sar || sar.status === 'draft') && isManage && (
            <AddSarDialog building={building} sar={sar} />
          )}
          {sar && <SignDocumentButton document={sar} onReady={refetch} onSign={refetch} />}
        </Stack>
      </CardContent>
    </Card>
  )
}

export { Sar }
