import { FormDialog } from '../common/FormDialog'
import * as React from 'react'
import { useState } from 'react'
import PrdChangeForm from './PrdChangeForm'
import { TPrdDocument } from '../../types/prd'
import { useAddPrdMutation } from '../../store/document'
import { useAddChangeMutation } from '../../store/prd'
import { toastError } from '../../util/toastError'

type Props = {
  onSave?: () => void
  onClose: () => void
  document: TPrdDocument
  open: boolean
}

const PrdChangeAddDialog = ({ onSave, document, open, onClose }: Props) => {
  const id = 'add-prd-change-dialog'
  const [isLoading, setIsLoading] = useState(false)

  const [addChange] = useAddChangeMutation()

  const handleSubmit = (values: any, methods: any) => {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('file', values.file)
    formData.append('document_id', document.id)
    formData.append('number', values.number)
    formData.append('description', values.description)

    addChange({ buildingId: document.building_id, body: formData })
      .unwrap()
      .then((res) => {
        methods.reset()
        setIsLoading(false)
        onSave && onSave()
      })
      .catch(async (e) => {
        await toastError(e)
        setIsLoading(false)
      })
  }

  return (
    <>
      <FormDialog
        formId={id}
        open={open}
        onClose={onClose}
        title={'Добавить изменение'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <PrdChangeForm id={id} onSubmit={handleSubmit} />
      </FormDialog>
    </>
  )
}

export default PrdChangeAddDialog
