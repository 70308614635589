import { useState } from 'react'
import { LightButton } from '../../common/LightButton'
import { Add } from '@mui/icons-material'
import * as React from 'react'
import { ActOSRForm } from './ActOSRForm'
import { FormFullscreenDialog } from '../../common/FormFullscreenDialog'
import { useAddDocumentMutation, useEditDocumentMutation } from '../../../store/document'
import { documentType, IDocument } from '../../../types/document'
import { toastError } from '../../../util/toastError'
import { toast } from 'react-toastify'

type ecType = {
  id: string,
  type: string
}

const ec = (data: any) => {
  const res: ecType[] = []
  Object.keys(data).forEach(key => {
    if (data[key]) {
      res.push({id: data[key], type: key})
    }
  })
  return res
}

type Props = {
  buildingId: string
  isCreate: boolean
  document?: IDocument
}
const ActOSRDialog = ({ buildingId, isCreate, document }: Props) => {
  const id = 'act-osr-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [add] = useAddDocumentMutation()
  const [edit] = useEditDocumentMutation()

  const values = document ? {
    ...document,
    otherEmployees: document.employees?.otherRepresentative.map(o => o.employee_id) || [],
    items: document.over[documentType.GWJ3Item]?.map(d => d.over_id) || [],
    companies: {
      developer: document.companies?.developer[0].company_id,
      buildingContractor: document.companies?.buildingContractor[0].company_id,
      projectDocumentationContractor: document.companies?.projectDocumentationContractor[0].company_id,
      workExecutor: document.companies?.workExecutor[0].company_id,
    },
    employees: {
      developerRepresentative: document.employees?.developerRepresentative[0].employee_id,
      buildingContractorRepresentative: document.employees?.buildingContractorRepresentative[0].employee_id,
      constructionManagementRepresentative: document.employees?.constructionManagementRepresentative[0].employee_id,
      projDocContractRepresentative: document.employees?.projDocContractRepresentative[0].employee_id,
      workExecutorRepresentative: document.employees?.workExecutorRepresentative[0].employee_id,
    }
  } : undefined


  const handleSubmit = (values: any, methods: any) => {

    const employees = [
      ...ec(values.employees),
      ...values.otherEmployees.map((id: string[]) => ({id, type: 'otherRepresentative'}))
    ]

    setIsLoading(true)

    if (isCreate) {
      add({
        buildingId: buildingId,
        body: {
          type: documentType.ActOSRSignedPart,
          date: values.date,
          number: values.number,
          data: values.data,
          companies: ec(values.companies),
          employees,
          over: values.items.map((id: string) => ({id, type: documentType.GWJ3Item}))
        }
      }).unwrap()
        .then(res => {
          setOpen(false)
          toast.success('Акт добавлен')
        })
        .catch(toastError)
        .finally(() => setIsLoading(false))
    } else {
      edit({
        buildingId: buildingId,
        body: {
          document_id: document?.id,
          type: documentType.ActOSRSignedPart,
          date: values.date,
          number: values.number,
          data: values.data,
          companies: ec(values.companies),
          employees,
          over: values.items.map((id: string) => ({id, type: documentType.GWJ3Item}))
        }
      }).unwrap()
        .then(res => {
          setOpen(false)
          toast.success('Акт сохранен')
        })
        .catch(toastError)
        .finally(() => setIsLoading(false))
    }

  }


  return (
    <>
      <LightButton startIcon={isCreate && <Add />} onClick={() => setOpen(true)}>
        {isCreate ? "Добавить" : "Редактировать"}
      </LightButton>
      <FormFullscreenDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={isCreate ? "Добавить акт" : "Редактировать акт"}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <ActOSRForm
          buildingId={buildingId}
          id={id}
          onSubmit={handleSubmit}
          values={values}
        />
      </FormFullscreenDialog>
    </>
  )
}

export {ActOSRDialog}
