import { extDocTypesList, TExternalDocumentType } from '../../types/external-document'
import { object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Chip, Stack } from '@mui/material'
import FormSelect from '../form/FormSelect'
import FormInput from '../form/FormInput'
import FormInputDate from '../form/FormInputDate'
import React, { useCallback, useEffect, useState } from 'react'

const typeItems = [
  { value: '', label: '-' },
  ...Object.keys(extDocTypesList).map((key) => ({
    label: extDocTypesList[key as TExternalDocumentType],
    value: key,
  })),
]

const schema = {
  name: string().min(0),
  type: string().min(0),
  number: string().min(0),
  dateFrom: string().min(0),
  dateTo: string().min(0),
}
const registerSchema = object(schema)

interface Props {
  values?: any
  onChange: (values: any, name: string | undefined, type: string | undefined) => void
}

const ExtDocsFilter = ({ values, onChange }: Props) => {
  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values || {},
  })

  const [data, setData] = useState<Record<string, string>>()

  const { watch, setValue } = methods

  useEffect(() => {
    const subscription = watch((data, { name, type }) => {
      onChange(data, name, type)
      setData(data as TypeOf<typeof registerSchema>)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const handleRemove = (key: string) => {
    setValue(key as any, '')
  }

  const clear = () => {
    Object.keys(schema).forEach((key: any) => {
      setValue(key, '')
    })
  }

  const isFilled = useCallback(() => {
    // fixme: простая проверка на пустую форму, нужно проверять на эквивалентность defaultValues
    return !!data && !!Object.values(data).join('')
  }, [data])

  return (
    <FormProvider {...methods}>
      <Box id={'ext-doc-filter'} component="form" noValidate autoComplete="off">
        <FormSelect name="type" label="Тип" items={typeItems} size={'small'} />
        <Stack direction={'row'} useFlexGap spacing={2}>
          <FormInput name="name" label="Наименование" size={'small'} />
          <FormInput name="number" label="Номер" size={'small'} />
        </Stack>
        <Stack direction={'row'} useFlexGap spacing={2}>
          <FormInputDate name="dateFrom" label={'С даты'} size={'small'} />
          <FormInputDate name="dateTo" label={'По дату'} size={'small'} />
        </Stack>
      </Box>
      {data && Object.keys(data).length > 0 && (
        <Box sx={{ pt: 2 }}>
          <Stack direction="row" spacing={1} useFlexGap flexWrap={'wrap'}>
            {data.type && (
              <Chip
                label={`Тип: ${extDocTypesList[data.type as TExternalDocumentType]}`}
                onDelete={() => handleRemove('type')}
              />
            )}
            {data.name && (
              <Chip label={`Наименование: ${data.name}`} onDelete={() => handleRemove('name')} />
            )}
            {data.number && (
              <Chip label={`Номер: ${data.number}`} onDelete={() => handleRemove('number')} />
            )}
            {data.dateFrom && (
              <Chip
                label={`С даты: ${data.dateFrom.substring(0, 10)}`}
                onDelete={() => handleRemove('dateFrom')}
              />
            )}
            {data.dateTo && (
              <Chip
                label={`По дату: ${data.dateTo.substring(0, 10)}`}
                onDelete={() => handleRemove('dateTo')}
              />
            )}

            {isFilled() && <Chip label={`Очистить`} variant={'outlined'} onDelete={clear} />}
          </Stack>
        </Box>
      )}
    </FormProvider>
  )
}

export default ExtDocsFilter
