import { companyRoles, TCompany, TCompanyRole } from '../../types/building'
import Box from '@mui/material/Box'
import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ColoredAvatar } from '../common/ColoredAvatar'
import Diversity3Icon from '@mui/icons-material/Diversity3'

type Props = {
  company: TCompany
  isAdmin: boolean
}

export const CompanyItem = ({ company, isAdmin }: Props) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`company/${company.id}`)
  }

  return (
    <>
      <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
        <Stack direction={'row'} spacing={1}>
          <Box>
            <ColoredAvatar text={company.role}>
              <Diversity3Icon />
            </ColoredAvatar>
          </Box>
          <Box sx={{ flexGrow: '1' }}>
            <Box sx={{ fontWeight: 'medium' }}>{companyRoles[company.role]}</Box>
            <Box>
              <Typography>{company.name}</Typography>
            </Box>
            <Typography variant={'caption'} display={'block'} color={'grey'}>
              ИНН: {company.inn},{company.type === 'legal' ? ' ОГРН' : ' ОГРНИП'}: {company.ogrn}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </>
  )
}
