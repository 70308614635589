import { TBuilding, TEmployee } from '../../../../types/building'
import { RightPanel, SkidPagination, useSkidPagination } from '../../../common'
import {
  Box,
  Button,
  CardHeader,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import { useGetWorksQuery } from '../../../../store/works'
import { useParams } from 'react-router-dom'
import { useGetEmployeesQuery } from '../../../../store/employees'
import { formatDateTime } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import { useMemo, useState } from 'react'
import { Item3View } from './Item3View'
import AddItem3Dialog from './AddItem3Dialog'
import { useGetDocumentsQuery, useRemoveDocumentMutation } from '../../../../store/document'
import { documentType, IDocument } from '../../../../types/document'
import { toastError } from '../../../../util/toastError'
import { useChecked } from '../../../../util/useChecked'
import { useAuthorization } from '../../../../auth'
import { useGetUserQuery } from '../../../../store/user'
import { toast } from 'react-toastify'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

export const Gwj3ItemsList = ({ building }: { building: TBuilding }) => {
  const { journalId } = useParams()
  const { pages, page, offset, limit, onPageChange, setTotal } = useSkidPagination(20)

  const { data, isLoading, isSuccess, refetch } = useGetDocumentsQuery({
    buildingId: building.id,
    params: {
      type: documentType.GWJ3Item,
      parent_id: journalId,
    },
  })

  const {
    data: works,
    isLoading: isWorksLoading,
    isSuccess: isWorksSuccess,
  } = useGetWorksQuery(building.id)

  const { data: employees } = useGetEmployeesQuery(building.id)

  const [removeItem] = useRemoveDocumentMutation()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentItem = useMemo(() => {
    return data && data.items.find((item) => item.id === currentId)
  }, [data, currentId])

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isCreate = useMemo(() => authManager.vote('Document.GWJ3Item', 'create'), [])
  const isManage = useMemo(() => {
    return (
      (currentItem &&
        user &&
        authManager.vote('Document.GWJ3Item', 'manage', currentItem.owner_id === user.id) &&
        currentItem.status === 'draft') ||
      false
    )
  }, [currentItem, user])

  const filteredItems = useMemo(() => {
    return data ? data.items.slice(offset, offset + limit) : []
  }, [data, offset, limit])

  const { onCheck, checked, isCheckedAll, isIndeterminate } = useChecked(
    filteredItems.map((i) => i.id)
  )

  if (isSuccess) {
    setTotal(data.metadata.total)
  }

  if (isLoading || isWorksLoading || data === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewItem = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const handleRemove = (id: string | undefined) => {
    if (id) {
      removeItem({
        buildingId: building.id,
        body: {
          type: documentType.GWJ3Item,
          document_id: id,
        },
      })
        .unwrap()
        .then(() => {
          setCurrentId(undefined)
          setDrawerOpen(false)
          toast.success('Запись удалена')
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <Paper>
        <TableContainer>
          <CardHeader
            title={'Сведения о выполнении работ'}
            action={isCreate && <AddItem3Dialog building={building} />}
          />
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: '50px' }}>
                  <Checkbox
                    checked={isCheckedAll}
                    value={'all'}
                    onChange={onCheck}
                    indeterminate={isIndeterminate}
                  />
                </TableCell>
                <TableCell>Работа, место проведения</TableCell>
                <TableCell>Ответственный</TableCell>
                <TableCell>Дата</TableCell>
                <TableCell>Статус документа</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems.map((item) => (
                <TableRow key={item.id} hover sx={{ cursor: 'pointer' }}>
                  <TableCell>
                    <Checkbox
                      checked={checked.includes(item.id)}
                      value={item.id}
                      onChange={onCheck}
                    />
                  </TableCell>
                  <TableCell onClick={() => viewItem(item.id)}>
                    <Box>
                      <Typography variant={'body1'}>
                        {works && works[item.works[0]].name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant={'caption'} color={'grey'}>
                        {item.data?.location?.place}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell onClick={() => viewItem(item.id)}>
                    {employees && signerName(employees, item)}
                  </TableCell>
                  <TableCell onClick={() => viewItem(item.id)}>
                    {formatDateTime(item.date)}
                  </TableCell>
                  <TableCell onClick={() => viewItem(item.id)}>
                    <StatusChip status={item.status} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data.items && data.items.length > 0 && (
            <SkidPagination page={page} pages={pages} onChange={onPageChange} />
          )}
        </TableContainer>
      </Paper>
      {currentItem && (
        <RightPanel
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          onOpen={() => setDrawerOpen(true)}
          actions={
            <>
              {isManage && (
                <Button
                  variant={'outlined'}
                  color={'error'}
                  onClick={() => handleRemove(currentItem?.id)}
                >
                  Удалить
                </Button>
              )}
            </>
          }
        >
          <Item3View
            building={building}
            item={currentItem}
            onReady={refetch}
            onSign={refetch}
            onChange={refetch}
            isManage={isManage}
          />
        </RightPanel>
      )}
    </>
  )
}
