import { TExternalDocument } from '../../types/external-document'
import React, { useState } from 'react'
import ExtDocViewDialog from './ExtDocViewDialog'
import { Link } from '@mui/material'
import { formatDate } from '../../util/date'

type Props = {
  document: TExternalDocument
  showDate?: boolean
  showNumber?: boolean
}

const extDocName = (doc: TExternalDocument, showNumber: boolean, showDate: boolean) => {
  return (
    doc.name +
    (showNumber && doc.number ? ' № ' + doc.number : '') +
    (showDate && doc.date ? ' ' + formatDate(doc.date) : '')
  )
}

const ExtDocLine = ({ document, showNumber = true, showDate = true }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Link underline={'hover'} sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)}>
        {extDocName(document, showNumber, showDate)}
      </Link>
      <ExtDocViewDialog document={document} open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default ExtDocLine
