import {
  BadgeProps,
  Badge,
  Box,
  Button,
  Card,
  CardHeader,
  LinearProgress,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import {
  companyRoles,
  TBuilding,
  TCompany,
  TCompanyRole,
  TCompanyType,
  TEmployee,
} from '../../types/building'
import { CompanyItem } from './CompanyItem'
import { CompanyDialogForm } from './form/CompanyDialogForm'
import {
  useAttachCompanyIEMutation,
  useAttachCompanyLegalMutation,
  useGetCompaniesQuery,
} from '../../store/companies'
import { toast } from 'react-toastify'
import { parseError, parseErrors } from '../../api'
import { Add } from '@mui/icons-material'
import { BaseContainer } from '../common/Container'
import { useAuthorization } from '../../auth'
import { useGetEmployeesQuery } from '../../store/employees'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import { LightButton } from '../common/LightButton'

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))

const employeesCount = (companyId: string, employees: TEmployee[]): number => {
  return employees.filter((e) => e.company_id === companyId).length
}

const EmployeesCount = ({ company, employees }: { company: TCompany; employees: TEmployee[] }) => {
  const count = useMemo(() => employeesCount(company.id, employees), [company, employees])

  return (
    <StyledBadge
      badgeContent={count + ''}
      color={count > 0 ? 'success' : 'warning'}
      title={'Сотрудников'}
    >
      <PeopleAltOutlinedIcon />
    </StyledBadge>
  )
}

export const BuildingCompanies = ({ building }: { building: TBuilding }) => {
  const [open, setOpen] = useState(false)
  const [values, setValues] = useState<Record<string, string>>({ type: 'legal' })
  const { data: companies, isLoading } = useGetCompaniesQuery(building.id)
  const { data: employees } = useGetEmployeesQuery(building.id)
  const [attachLegal] = useAttachCompanyLegalMutation()
  const [attachIe] = useAttachCompanyIEMutation()
  const authManager = useAuthorization()

  if (isLoading || companies === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const isManage: boolean = authManager.vote('Company', 'manage')

  const handleAddClick = (role?: TCompanyRole) => {
    setValues(role ? { role, type: 'legal' } : { type: 'legal' })
    setOpen(true)
  }

  const handleAddSubmit = (values: any, type: TCompanyType) => {
    const attach = type === 'legal' ? attachLegal : attachIe
    attach({ buildingId: building.id, body: values })
      .unwrap()
      .then((res) => {
        toast.success('Компания добавлена', { position: 'top-center' })
        setOpen(false)
      })
      .catch(async (error) => {
        const text = await parseError(error)
        if (text) {
          toast.error(text, { position: 'top-center' })
        } else {
          const errors = (await parseErrors(error)) as Record<string, string>
          let errorsText = ''
          for (const f in errors) {
            errorsText += errors[f] + '\n'
          }
          toast.error(errorsText, { position: 'top-center' })
        }
      })
  }

  return (
    <BaseContainer>
      <TableContainer component={Card}>
        <CardHeader
          action={
            isManage && (
              <LightButton startIcon={<Add />} onClick={() => handleAddClick()}>
                Добавить
              </LightButton>
            )
          }
          title={'Компании'}
          subheader={'участники'}
        />
        <Table>
          <TableBody>
            {companies &&
              Object.keys(companyRoles).map((role) => {
                return companies
                  .filter((item) => item.role === role)
                  .map((company) => (
                    <TableRow key={company.id} hover>
                      <TableCell>
                        <CompanyItem company={company} isAdmin={isManage} />
                      </TableCell>
                      <TableCell>
                        {employees && <EmployeesCount company={company} employees={employees} />}
                      </TableCell>
                    </TableRow>
                  ))
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <CompanyDialogForm
        type={'add'}
        open={open}
        onClose={() => setOpen(false)}
        values={values}
        onSubmit={handleAddSubmit}
        building={building}
      />
    </BaseContainer>
  )
}
