import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import * as React from 'react'
import { TExternalDocument, TExternalDocumentType } from '../../types/external-document'
import ExtDocsListFilter from './ExtDocsListFilter'
import { AddDialog } from './index'
import { useOutletContext } from 'react-router-dom'
import { IOutletBuilding } from '../../types/outlet-context'

type Props = {
  documents: TExternalDocument[]
  open: boolean
  onClose: () => void
  onDocumentClick: (id: string) => void
  variant: 'full' | 'select'
  docType?: TExternalDocumentType
  onAdd?: (id: string) => void
}
const ExtDocListDialog = ({ documents, onClose, open, onDocumentClick, docType, onAdd }: Props) => {
  const { building } = useOutletContext<IOutletBuilding>()

  const handleAdd = (res: any) => {
    onAdd && onAdd(res.id)
  }

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth scroll={'paper'}>
        <DialogTitle>Выбор документа</DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <ExtDocsListFilter
            documents={documents}
            variant={'full'}
            onRowClick={onDocumentClick}
            docType={docType}
          />
        </DialogContent>
        <DialogActions>
          <AddDialog building={building} onAdd={handleAdd} />
          <Button onClick={onClose}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ExtDocListDialog
