import { array, object, string, TypeOf, union } from 'zod'
import { ceRoles, employeeRoles, TBuilding, TCompany, TEmployeeRole } from '../../types/building'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useApi } from '../../api/useApi'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod/dist/zod'
import { useInviteMutation } from '../../store/user'
import { useAttachEmployeeMutation, useGetEmployeesQuery } from '../../store/employees'
import { toast } from 'react-toastify'
import {
  Alert,
  Box,
  Button,
  debounce,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import FormInput from '../form/FormInput'
import { LoadingButton } from '@mui/lab'
import FormSelect from '../form/FormSelect'
import { worksList } from '../../util/works'
import { useGetGroupsQuery, useGetWorksQuery } from '../../store/works'
import { toastError } from '../../util/toastError'
import { LightButton } from '../common/LightButton'

const schema1 = object({
  email: string().email({ message: 'Некорректный адрес' }).min(1, 'Необходимо заполнить'),
  user_id: string().min(1),
  role: string().min(1, 'Необходимо заполнить'),
  position: string().optional(),
  works: array(string()),
})
const schema2 = object({
  email: string().email({ message: 'Некорректный адрес' }).min(1, 'Необходимо заполнить'),
  role: string().min(1, 'Необходимо заполнить'),
  first_name: string().min(1, 'Необходимо заполнить'),
  last_name: string().min(1, 'Необходимо заполнить'),
  middle_name: string().optional(),
  position: string().optional(),
  works: array(string()),
})

const schema = union([schema1, schema2])

export const AddEmployeeDialog = ({
  building,
  company,
}: {
  building: TBuilding
  company: TCompany
}) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isUserLoading, setIsUserLoading] = useState(false)
  const [user, setUser] = useState<any | null>(null)
  const [role, setRole] = useState<string | undefined>()

  const api = useApi()

  const registerSchema = union([schema1, schema2])

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: { works: [] },
  })

  const { handleSubmit, watch, reset, setValue } = methods

  const roles = useMemo(() => {
    const rolesForCompany = ceRoles[company.role]
    return rolesForCompany.map((role) => {
      return {
        label: employeeRoles[role as TEmployeeRole],
        value: role,
      }
    })
  }, [])

  const searchUser = useCallback(
    debounce((val: string | undefined) => {
      if (!isUserLoading && val && val?.length > 6) {
        setIsUserLoading(true)
        setUser(null)
        setValue('user_id', '')

        api
          .post('/v1/auth/by-email', {
            email: val,
          })
          .then((res) => {
            setUser(res)
            if (res && res.id) {
              setValue('user_id', res.id)
            }
          })
          .catch(toastError)
          .finally(() => {
            setIsUserLoading(false)
          })
      }
    }, 500),
    []
  )

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'role') {
        setRole(value[name])
      }
      if (name === 'email') {
        setUser(null)
        searchUser(value[name])
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const [invite] = useInviteMutation()
  const [attach] = useAttachEmployeeMutation()
  const { data: employees, isLoading: isEmployeesLoading } = useGetEmployeesQuery(building.id)
  const { data: works } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)

  const worksItems = useMemo(
    () => worksList(groups, works, company.works || []) || [],
    [groups, works, company]
  )

  if (isEmployeesLoading || employees === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const sendData = useCallback(
    (values: any) => {
      setIsLoading(true)
      attach({
        buildingId: building.id,
        body: {
          ...values,
          company_id: company.id
        }
      })
        .unwrap()
        .then((res) => {
          setIsLoading(false)
          toast.success('Готово!')
          setOpen(false)
          reset()
        })
        .catch(toastError)
        .finally(() => {
          setIsLoading(false)
        })
    },
    [attach, building, company]
  )

  const onSubmitHandler = (values: any) => {
    if (user && user.status === 'active') {
      sendData(values)
    } else {
      invite(values.email)
        .unwrap()
        .then((res) => {
          sendData({ ...values, user_id: res.id })
        })
        .catch(toastError)
    }
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить
      </LightButton>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth={'lg'} fullWidth>
        <DialogTitle>{'Пригласить пользователя'}</DialogTitle>
        <DialogContent>
          <FormProvider {...methods}>
            <Box
              component="form"
              id={'add-building-user-form'}
              onSubmit={handleSubmit(onSubmitHandler)}
              noValidate
              autoComplete="off"
            >
              <Box sx={{ pt: 1 }}>
                <FormInput name="email" label="Адрес электронной почты" color={user && 'success'} />
                <input type="hidden" name="user_id" />
                {(!user || !user.profile) && (
                  <>
                    <FormInput name={'last_name'} label={'Фамилия'} />
                    <FormInput name={'first_name'} label={'Имя'} />
                    <FormInput name={'middle_name'} label={'Отчество'} />
                  </>
                )}
                {user?.profile && (
                  <Alert severity="success" sx={{ mb: 2 }}>
                    {user.profile}
                  </Alert>
                )}
                <FormInput name="position" label="Должность" />
                <FormSelect name="role" label="Назначить как" items={roles} />
                {['building_contractor', 'contractor'].includes(company.role) &&
                  role === 'auth_itp' && (
                    <FormSelect
                      multiple
                      name="works"
                      label="Список выполняемых работ"
                      items={worksItems}
                      hint="Заполняется в случае необходимости ограничения выполняемых работ для сотрудника"
                    />
                  )}
              </Box>
            </Box>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant={'contained'}
            type="submit"
            form={'add-building-user-form'}
            loading={isLoading}
            color={'success'}
          >
            {'Отправить'}
          </LoadingButton>

          <Button onClick={() => setOpen(false)}>Закрыть</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
