import { TBuilding, TEmployee } from '../../../types/building'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { formatDate } from '../../../util/date'
import * as React from 'react'
import { ReactNode, useMemo } from 'react'
import { useGetMaterialsQuery, useGetUnitsQuery } from '../../../store/lists'
import { useGetCompaniesQuery } from '../../../store/companies'
import { StatusChip } from '../../common/StatusChip'
import { Delete } from '@mui/icons-material'
import { useGetExternalDocumentsQuery } from '../../../store/externalDocuments'
import ParseError from '../../../api/parseError'
import { toast } from 'react-toastify'
import AddItemExtDocDialog from './AddItemExtDocDialog'
import { IDocument } from '../../../types/document'
import { IStore } from '../../../types/store'
import { useRemoveExtDocMutation } from '../../../store/document'
import { useGetEmployeesQuery } from '../../../store/employees'
import { DataRow } from '../../common/DataRow'
import { EditItemDialog } from './EditItemDialog'
import { useAuthorization } from '../../../auth'
import { useGetUserQuery } from '../../../store/user'
import { SignDocument } from '../../document/SignDocument'
import ExtDocLine from '../../ExternalDocuments/ExtDocLine'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

const AccordionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant={'subtitle1'} sx={{ fontWeight: 'medium', flexGrow: 1 }}>
      {children}
    </Typography>
  )
}

const CountChip = ({ length = 0 }: { length: number }) => {
  return length > 0 ? (
    <Box sx={{ width: '30px', mr: 1 }}>
      <Chip color="success" label={length} size="small" />
    </Box>
  ) : (
    <></>
  )
}

type Props = {
  building: TBuilding
  item: IDocument
  store: IStore
  onChange: () => void
  isManage: boolean
}

export const ItemView = ({ building, item, store, onChange }: Props) => {
  const { data: units } = useGetUnitsQuery(building.id)
  const { data: materials } = useGetMaterialsQuery(building.id)
  const { data: employees } = useGetEmployeesQuery(building.id)
  const { data: companies } = useGetCompaniesQuery(building.id)
  const { data: extDocs } = useGetExternalDocumentsQuery({ buildingId: building.id })
  const [removeExtDoc] = useRemoveExtDocMutation()

  const authManager = useAuthorization()
  const { data: user } = useGetUserQuery()
  const isManage = useMemo(() => {
    return  (user && item.status === 'draft' &&
      authManager.vote('Document.IMCItem', 'manage', item.owner_id === user.id)) || false
  }, [user, item])

  const removeExtDocHandler = (docId: string) => {
    if (window.confirm('Удалить документ из записи?')) {
      removeExtDoc({
        buildingId: building.id,
        body: {
          document_id: item.id,
          external_document_id: docId,
        },
      })
        .unwrap()
        .then((res) => {
          onChange()
        })
        .catch(async (e) => {
          const error = await ParseError(e)
          toast.error(error)
        })
    }
  }

  const unit = units && units[store.unit_id] ? units[store.unit_id].name : ''

  return (
    <>
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader
              title={'Сведения о верификации материала'}
              action={<StatusChip status={item.status} />}
            />
            <CardContent>
              <DataRow label={'Материал'} text={materials && materials[store.material_id].name} />
              <DataRow label={'Объем'} text={`${store.value} ${unit}`} />
              <DataRow label={'Остаток'} text={`${store.balance} ${unit}`} />
              <DataRow
                label={'Поставщик'}
                text={`${
                  item.companies.supplier[0] &&
                  companies &&
                  companies.find((c) => c.id === item.companies.supplier[0].company_id)?.name
                }`}
              />
              <DataRow label={'Дата поставки'} text={formatDate(item.date)} />
              <DataRow label={'Ответственный'} text={employees && signerName(employees, item)} />
              <DataRow
                label={'Заключение о качестве'}
                text={item?.data?.quality_conclusion || ''}
              />
            </CardContent>
            <CardContent>
              {isManage && item.status === 'draft' && (
                <EditItemDialog item={item} store={store} building={building} onAdd={onChange} />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={'ДПК'} subheader={'паспорта, сертификаты'} />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Документ</TableCell>
                  {isManage && (
                    <TableCell align="right">
                      <AddItemExtDocDialog
                        type="dpk"
                        item={item}
                        onAdd={onChange}
                      ></AddItemExtDocDialog>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {item.external_documents?.dpk &&
                  item.external_documents.dpk.map((extDoc) => {
                    if (!extDocs) return ''
                    const doc = extDocs.items.find((doc) => doc.id === extDoc.external_document_id)
                    if (!doc) return ''
                    return (
                      <TableRow key={extDoc.id}>
                        <TableCell>
                          <ExtDocLine document={doc} showDate={false} />
                          <Typography variant="subtitle2" color="text.disabled">
                            {formatDate(doc.date)}
                          </Typography>
                        </TableCell>
                        {isManage && (
                          <TableCell align="right">
                            <IconButton
                              color="error"
                              title="Удалить"
                              onClick={() => removeExtDocHandler(extDoc.id)}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Card>
        </Grid>
      </Grid>
      <Box sx={{mt:2}}>
        <SignDocument document={item} onReady={onChange} onSign={onChange} />
      </Box>
    </>
  )
}
