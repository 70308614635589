import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { formatDate, formatDateTime } from '../../../../util/date'
import { StatusChip } from '../../../common/StatusChip'
import * as React from 'react'
import { IDocument } from '../../../../types/document'
import { TWork, TWorkGroup } from '../../../../types/works'
import { TEmployee } from '../../../../types/building'
import { locationString } from '../../../../util/location'
import { useMemo, useState } from 'react'
import LocationAddDialog from '../../../location/LocationAddDialog'
import { Add, Delete, Edit } from '@mui/icons-material'
import { useAddLocationMutation, useRemoveLocationMutation } from '../../../../store/document'
import { toastError } from '../../../../util/toastError'
import { DataRow } from '../../../common/DataRow'
import { MoreVertMenu } from '../../../menu'

const signerName = (employees: TEmployee[], item: IDocument): string => {
  return (
    employees.find((em) => em.id === item.employees.signer[0].employee_id)?.profile.name
      .full_name || ''
  )
}

type Props = {
  item: IDocument
  groups: Record<string, TWorkGroup>
  works: Record<string, TWork>
  employees: TEmployee[]
  isManage: boolean
}

const Item3ViewData = ({ item, works, groups, employees, isManage }: Props) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [addLocation] = useAddLocationMutation()
  const [removeLocation] = useRemoveLocationMutation()

  const workName = useMemo(() => {
    const work = works[item.works[0]]
    const group = groups[work.group_id]

    return `${group.name} / ${work.name}`
  }, [works, groups])

  const submitHandler = (values: any, methods: any) => {
    setIsLoading(true)
    addLocation({
      buildingId: item.building_id,
      body: {
        document_id: item.id,
        location: values,
      },
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false)
        setOpen(false)
      })
      .catch(async (e) => {
        await toastError(e)
        setIsLoading(false)
      })
  }

  const removeLocationHandler = () => {
    removeLocation({
      buildingId: item.building_id,
      body: {
        document_id: item.id,
      },
    })
      .unwrap()
      .then((res) => {
        setIsLoading(false)
        setOpen(false)
      })
      .catch(async (e) => {
        await toastError(e)
        setIsLoading(false)
      })
  }

  return (
    <>
      <Card variant={'elevation'} elevation={0} sx={{ mb: 2 }}>
        <CardHeader
          action={
            <MoreVertMenu onOpen={console.log} onClose={console.log} open={false}>
              <MenuItem>{'Редактировать'}</MenuItem>
            </MoreVertMenu>
          }
          avatar={<StatusChip status={item.status} />}
        />
        <Divider />
        <CardContent sx={{ p: 2 }}>
          <DataRow label={'Работа'} text={workName} />
          <DataRow label={'Дата выполнения работ'} text={formatDate(item.date)} />
          <DataRow label={'Местоположение'}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              {item.location && (
                <Typography variant={'body2'}>{locationString(item.location)}</Typography>
              )}
              {isManage && (
                <Box>
                  {item.location ? (
                    <>
                      <IconButton onClick={() => setOpen(true)} color={'primary'}>
                        <Edit />
                      </IconButton>
                      <IconButton onClick={removeLocationHandler} color={'error'}>
                        <Delete />
                      </IconButton>
                    </>
                  ) : (
                    <Button startIcon={<Add />} onClick={() => setOpen(true)}>
                      Добавить
                    </Button>
                  )}
                </Box>
              )}
            </Stack>
          </DataRow>
          <DataRow label={'Ответственный'} text={employees && signerName(employees, item)} />
        </CardContent>
      </Card>
      {isManage && (
        <LocationAddDialog
          open={open}
          isLoading={isLoading}
          onClose={() => setOpen(false)}
          onSubmit={submitHandler}
          values={item.location}
        />
      )}
    </>
  )
}

export { Item3ViewData }
