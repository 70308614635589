import { useConfirm as useConfirmOrig } from 'material-ui-confirm'

export const useConfirm = () => {
  const origConfirm = useConfirmOrig()

  return (options: any) => {
    return new Promise((resolve, reject) => {
      return origConfirm(options).then(resolve).catch(console.log)
    })
  }
}
