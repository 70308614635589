import { TBuilding, TCompany, TEmployee } from '../../types/building'
import { Card, CardContent, CardHeader, List, ListItem, Typography } from '@mui/material'
import React, { useMemo } from 'react'
import { useGetGroupsQuery, useGetWorksQuery } from '../../store/works'
import { TWork, TWorkGroup } from '../../types/works'
import { useAuthorization } from '../../auth'

type TGroupedWorks = { group: TWorkGroup; works: TWork[] }[]

const groupWorks = (
  employee: TEmployee,
  works: Record<string, TWork>,
  groups: Record<string, TWorkGroup>
): TGroupedWorks => {
  if (!employee.works) return []

  const companyWorks: string[] = employee.works
  const res: TGroupedWorks = []

  Object.values(groups).forEach((group) => {
    const groupWorks = Object.values(works).filter(
      (work) => companyWorks.includes(work.id) && work.group_id === group.id
    )
    if (groupWorks.length > 0) {
      res.push({
        group: group,
        works: groupWorks,
      })
    }
  })
  return res
}

type Props = {
  employee: TEmployee
  building: TBuilding
}
const EmployeeWorks = ({ employee, building }: Props) => {
  const authManager = useAuthorization()
  const isManage = authManager.vote('Company', 'manage')

  const { data: works } = useGetWorksQuery(building.id)
  const { data: groups } = useGetGroupsQuery(building.id)

  const groupedWorks = useMemo(() => {
    if (works && groups) {
      return groupWorks(employee, works, groups)
    }
    return []
  }, [employee, works, groups])

  return (
    <Card>
      <CardHeader title="Работы" subheader={'ограничение по выполняемым работам'} />
      <CardContent>
        {employee.works && employee.works.length > 0 ? (
          groupedWorks.map((groupData) => (
            <List key={groupData.group.id} dense disablePadding>
              <ListItem disablePadding>
                <Typography variant={'body1'}>{groupData.group.name}</Typography>
              </ListItem>
              <List dense>
                {groupData.works.map((work) => (
                  <ListItem key={work.id}>
                    <Typography variant={'subtitle2'} color={'text.disabled'}>
                      {work.name}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </List>
          ))
        ) : (
          <Typography variant={'body1'}>Без ограничений</Typography>
        )}
      </CardContent>
    </Card>
  )
}

export { EmployeeWorks }
