import * as React from 'react'
import { useGetExternalDocumentsQuery } from '../../store/externalDocuments'
import { TBuilding } from '../../types/building'
import { Box, Card, CardHeader, LinearProgress, TableContainer } from '@mui/material'
import { RightPanel } from '../common'
import { AddDialog, DocumentView } from './index'
import { useMemo, useState } from 'react'
import { useGetUserQuery } from '../../store/user'
import ExtDocActions from './ExtDocActions'
import ExtDocsListFilter from './ExtDocsListFilter'

const ExternalDocuments = ({ building }: { building: TBuilding }) => {
  const { data, isLoading, refetch } = useGetExternalDocumentsQuery({ buildingId: building.id })
  const { data: user } = useGetUserQuery()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [currentId, setCurrentId] = useState<string | undefined>()

  const currentDocument = useMemo(() => {
    return data && data.items.find((document) => document.id === currentId)
  }, [data, currentId])

  if (isLoading || data === undefined || user === undefined) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }

  const viewDocument = (id: string) => {
    setCurrentId(id)
    setDrawerOpen(true)
  }

  const onRemove = () => {
    setCurrentId(undefined)
    setDrawerOpen(false)
  }

  const onSaveDocument = (id: string) => {
    setCurrentId(id)
  }

  const handleAdd = (res: any) => {
    viewDocument(res.id)
  }

  return (
    <>
      <TableContainer component={Card}>
        <CardHeader
          title={'Внешние документы'}
          subheader={''}
          action={<AddDialog building={building} onAdd={handleAdd} />}
        />
        <ExtDocsListFilter documents={data.items} onRowClick={viewDocument} variant={'full'} />
      </TableContainer>
      <RightPanel
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        actions={
          currentDocument && (
            <ExtDocActions document={currentDocument} onSave={onSaveDocument} onRemove={onRemove} onSign={refetch} />
          )
        }
      >
        <DocumentView document={currentDocument} />
      </RightPanel>
    </>
  )
}

export default ExternalDocuments
