import {
  useChangeBuildingUserRoleMutation,
  useGetBuildingUsersQuery,
  useRemoveBuildingUserMutation,
} from '../../../store/buildings'
import {
  Avatar,
  Card,
  CardHeader,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material'
import { indigo } from '@mui/material/colors'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import DeleteIcon from '@mui/icons-material/Delete'
import React from 'react'
import { useGetUserQuery } from '../../../store/user'
import { AddBuildingUserDialog } from './AddBuildingUserDialog'
import { toast } from 'react-toastify'
import { useAuthorization } from '../../../auth'
import { useOutletContext } from 'react-router-dom'
import { BaseContainer } from '../../common/Container'
import { IOutletBuilding } from '../../../types/outlet-context'

export const BuildingUsers = () => {
  const { building } = useOutletContext<IOutletBuilding>()

  const authManager = useAuthorization()
  const { data: currentUser, isSuccess: isUserSuccess } = useGetUserQuery()
  const [changeRole] = useChangeBuildingUserRoleMutation()
  const [remove] = useRemoveBuildingUserMutation()
  const { data: users } = useGetBuildingUsersQuery(building.id)

  const isAdmin = authManager.vote('BuildingUser', 'manage')

  const handleRole = (userId: string, admin: boolean) => {
    changeRole({
      buildingId: building.id,
      body: { user_id: userId, role: admin ? 'admin' : 'reader' },
    })
      .unwrap()
      .then(() => {
        toast.success('Роль изменена')
      })
  }

  const handleRemove = (userId: string) => {
    remove({
      buildingId: building.id,
      userId,
    })
      .unwrap()
      .then(() => {
        toast.success('Пользователь отключен от объекта')
      })
  }

  return (
    <BaseContainer>
      <TableContainer component={Card}>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: indigo[500] }} aria-label="recipe">
              <ManageAccountsIcon />
            </Avatar>
          }
          action={<AddBuildingUserDialog building={building} />}
          title="Пользователи"
          subheader="администраторы/наблюдатели"
        />
        <Table>
          <TableBody>
            {users &&
              users.map((user) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Box sx={{ flexGrow: 1 }}>
                          {user.profile ? user.profile.name.full_name : user.id}
                        </Box>
                        {isAdmin &&
                          isUserSuccess &&
                          currentUser.id !== user.user_id &&
                          building.owner_id !== user.user_id && (
                            <>
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={user.role === 'admin'}
                                      onChange={(event) =>
                                        handleRole(user.user_id, event.target.checked)
                                      }
                                    />
                                  }
                                  label="Администратор"
                                />
                              </Box>
                              <Box>
                                <IconButton
                                  aria-label="delete"
                                  color={'error'}
                                  onClick={() => handleRemove(user.user_id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </>
                          )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </BaseContainer>
  )
}
