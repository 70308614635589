import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../../common/FormDialog'
import * as React from 'react'
import { useAddGwj3ItemAmountMutation } from '../../../../store/gwj'
import { useParams } from 'react-router-dom'
import Item3AmountForm from './Item3AmountForm'
import { useGetWorksQuery } from '../../../../store/works'
import { parseError } from '../../../../api'
import { IDocument } from '../../../../types/document'
import { TBuilding } from '../../../../types/building'
import { LightButton } from '../../../common/LightButton'

type Props = {
  item: IDocument
  itemMa: Record<string, number>
  onAdd: (methods: any) => void
}

const AddItem3AmountDialog = ({ item, itemMa, onAdd }: Props) => {
  const id = 'add-item-3-amount-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()
  const { data: works } = useGetWorksQuery(item.building_id)
  const [addAmount] = useAddGwj3ItemAmountMutation()

  const handleSubmit = (values: any, methods: any) => {
    const body = {
      journal_id: journalId,
      record_id: item.id,
      work_id: item.works[0],
      ...values,
    }

    setIsLoading(true)
    addAmount({ buildingId: item.building_id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Материал добавлен')
        onAdd(methods)
      })
      .catch(async (e) => {
        const error = await parseError(e)
        toast.error('Ошибка: ' + error)
        setIsLoading(false)
      })
  }

  if (!works) {
    return <>Loading...</>
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить использование материала'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <Item3AmountForm
          work={works[item.works[0]]}
          item={item}
          itemMa={itemMa}
          id={id}
          onSubmit={handleSubmit}
        />
      </FormDialog>
    </>
  )
}

export default AddItem3AmountDialog
