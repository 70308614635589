import { auth } from '../oauth/Auth'
import { Fancybox } from '@fancyapps/ui'
import { userSlideType } from '@fancyapps/ui/types/Carousel/types'
import { toastError } from './toastError'
import { parseError, parseErrors } from '../api'

const download = async (
  url: string,
  fileName: string,
  type: string,
  method = 'GET',
  body: any = {}
) => {
  const token = await auth.getToken().catch((e) => {
    window.location.reload()
  })

  const options: any = {
    method,
    headers: {
      Authorization: token,
      'Accept': 'application/json'
    },
  }

  if (method.toUpperCase() === 'POST' && body) {
    options.headers['Content-Type'] = 'application/json'
    options.body = JSON.stringify(body)
  }

  fetch(url, options)
    .then(async (response) => {
      if (!response.ok) {
        const message = await parseError(response)
        throw new Error(`Не удалось получить файл: ${message}`)
      }
      return response.blob()
    })
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob)

      if (type === 'application/pdf') {
        Fancybox.show([
          {
            src: objectUrl,
            type: 'pdf',
          } as userSlideType,
        ])
      } else if (type.startsWith('image/')) {
        Fancybox.show([
          {
            src: objectUrl,
            type: 'image',
          } as userSlideType,
        ])
      } else {
        const link: HTMLAnchorElement = document.createElement('a')
        link.href = objectUrl
        link.download = fileName
        link.click()
        URL.revokeObjectURL(objectUrl)
        link.remove()
      }
    }).catch(toastError)
}

export { download }
