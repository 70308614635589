import { TBuilding } from '../../../types/building'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'
import { FormDialog } from '../../common/FormDialog'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import ItemForm from './ItemForm'
import { LightButton } from '../../common/LightButton'
import { useAddDocumentMutation } from '../../../store/document'
import { documentType } from '../../../types/document'

const AddItem3Dialog = ({ building, onAdd }: { building: TBuilding; onAdd: () => void }) => {
  const id = 'add-item-imc-dialog'
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { journalId } = useParams()

  const [addDocument] = useAddDocumentMutation()

  const handleSubmit = (values: any, methods: any) => {
    const body = {
      ...values,
      type: documentType.IMCItem,
      parent_id: journalId,
      employees: [{ id: values.signer_id, type: 'signer' }],
      companies: [{ id: values.supplier_id, type: 'supplier' }],
    }

    setIsLoading(true)

    addDocument({ buildingId: building.id, body })
      .unwrap()
      .then(() => {
        methods.reset()
        setOpen(false)
        setIsLoading(false)
        toast.success('Документ добавлен')
        onAdd()
      })
      .catch(() => {
        toast.error('Произошла ошибка')
        setIsLoading(false)
      })
  }

  return (
    <>
      <LightButton startIcon={<Add />} onClick={() => setOpen(true)}>
        Добавить запись
      </LightButton>
      <FormDialog
        formId={id}
        open={open}
        onClose={() => setOpen(false)}
        title={'Добавить запись'}
        button={'Отправить'}
        isLoading={isLoading}
      >
        <ItemForm
          building={building}
          id={id}
          onSubmit={handleSubmit}
          // values={{date: '2023-06-20 15:30'}}
        />
      </FormDialog>
    </>
  )
}

export default AddItem3Dialog
