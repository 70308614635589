import { array, coerce, object, string, TypeOf } from 'zod'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Card, CardContent, CardHeader, Grid, Stack } from '@mui/material'
import FormInput from '../../form/FormInput'
import FormInputDate from '../../form/FormInputDate'
import FormSelect from '../../form/FormSelect'
import React, { useEffect, useMemo } from 'react'
import { useGetEmployeesQuery } from '../../../store/employees'
import { useGetCompaniesQuery } from '../../../store/companies'
import { employeesList } from '../../../util/employees'
import { companiesList } from '../../../util/companies'
import dayjs from 'dayjs'
import { useGetDocumentsQuery } from '../../../store/document'
import { documentType, IDocument } from '../../../types/document'
import { useGetGroupsQuery, useGetWorksQuery } from '../../../store/works'
import { formatDate } from '../../../util/date'
import { AutocompleteInput, FormTextarea } from '../../form'
import { worksListStrings } from '../../../util/works'


const getItemsDates = (items: IDocument[]) => {

  const dates = items.map(item => new Date(item.date).getTime())

  if (dates.length === 0) {
    dates.push(Date.now())
  }

  return {
    begin: dayjs(Math.min(...dates)).toISOString(),
    end: dayjs(Math.max(...dates)).toISOString()
  }

}

type Props = {
  buildingId: string
  values?: any
  onSubmit: (values: any, methods: any) => void
  id: string
}

const schema = {
  companies: object({
    developer: string().min(1, "Необходимо заполнить"),
    buildingContractor: string().min(1, "Необходимо заполнить"),
    projectDocumentationContractor: string().min(1, "Необходимо заполнить"),
    workExecutor: string().min(1, "Необходимо заполнить"),
  }),
  employees: object({
    developerRepresentative: string().min(1, "Необходимо заполнить"),
    buildingContractorRepresentative: string().min(1, "Необходимо заполнить"),
    constructionManagementRepresentative: string().min(1, "Необходимо заполнить"),
    projDocContractRepresentative: string(),
    workExecutorRepresentative: string()
  }),
  otherEmployees: array(string()),
  date: string().min(1, 'Необходимо заполнить дату'),
  number: string().min(1, 'Необходимо заполнить'),
  data: object({
    name: string(),
    dateBegin: string().min(1, "Необходимо заполнить"),
    dateEnd: string().min(1, "Необходимо заполнить"),
    additionalInfo: string(),
    allowedWorks: array(string()).min(1, "Необходимо заполнить")
  }),
  items: array(string()).min(1, "Необходимо выбрать"),
}
const registerSchema = object(schema)


const ActOSRForm = ({buildingId, values, onSubmit, id}: Props) => {

  const methods = useForm<TypeOf<typeof registerSchema>>({
    resolver: zodResolver(registerSchema),
    defaultValues: values ||
      {
        data: {name: 'Акт ОСР', allowedWorks: []},
        otherEmployees: [],
        date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        items: []
      },
  })

  const {
    handleSubmit,
    formState: {errors},
    watch,
    setValue
  } = methods

  const {data: items3} = useGetDocumentsQuery({buildingId, params: {type: documentType.GWJ3Item}})
  const {data: workGroups} = useGetGroupsQuery(buildingId)
  const {data: works} = useGetWorksQuery(buildingId)
  const { data: employees } = useGetEmployeesQuery(buildingId)
  const { data: companies } = useGetCompaniesQuery(buildingId)


  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // console.log(value, name, type)
      switch (name) {
        case 'items' : {
          const items = items3 ?
            items3.items.filter(item => value.items && value.items.includes(item.id)) : []
          const dates = getItemsDates(items)
          setValue('data.dateBegin', dates.begin)
          setValue('data.dateEnd', dates.end)
          break
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch, items3])


  const worksItems = useMemo(() => workGroups && works && worksListStrings(workGroups, works) || [], [workGroups, works])

  const developerItems = companies &&
    companiesList(companies, ['developer', 'customer', 'regional_operator', 'operating_person'])

  const buildingContractorItems = companies &&
    companiesList(companies, ['building_contractor', 'contractor'])

  const projectDocumentationContractorItems = companies &&
    companiesList(companies, ['designer'])

  const workExecutorItems = companies &&
    companiesList(companies, ['building_contractor', 'contractor'])

  const developerRepresentativeItems= companies && employees ?
    employeesList(companies, employees, ['developer', 'developer_control', 'customer', 'customer_control', 'regional_operator', 'operating_person'], 'auth_representative_control'): []

  const buildingContractorRepresentativeItems= companies && employees ?
    employeesList(companies, employees, ['building_contractor', 'contractor'], 'auth_representative'): []

  const constructionManagementRepresentativeItems= companies && employees ?
    employeesList(companies, employees, ['building_contractor', 'contractor'], 'auth_representative_control'): []

  const projDocContractRepresentativeItems= companies && employees ?
    employeesList(companies, employees, ['designer'], 'auth_representative_doc_control'): []

  const workExecutorRepresentativeItems= companies && employees ?
    employeesList(companies, employees, ['building_contractor', 'contractor'], 'auth_representative'): []

  const otherEmployeesItems= companies && employees ?
    employeesList(companies, employees, ['building_contractor', 'contractor'], 'auth_representative'): []


  const items3List = items3 && works ? items3.items.map(i => ({
    label: works[i.works[0]].name,
    value: i.id,
    description: formatDate(i.date)
  })) : []


  const submitHandler = (values: any) => {
    onSubmit(values, methods)
  }


  return (
    <FormProvider {...methods}>
      <Box
        id={id}
        component="form"
        onSubmit={handleSubmit(submitHandler)}
        noValidate
        autoComplete="off"
      >
        <Stack direction={"column"} spacing={2}>
          <Card>
            <CardContent>
              <FormInputDate name="date" label="Дата составления акта" />
              <Grid container sx={{ flexGrow: 1 }} spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormInput name="data.name" label="Наименование акта" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput name="number" label="Номер акта" />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Card>
            <CardHeader
              title="Организации"
              subheader="Участники"
            />
            <CardContent>
              <FormSelect
                name="companies.developer"
                label="Застройщик (Технический заказчик, Лицо, ответственное за эксплуатацию, Региональный оператор)"
                items={developerItems}
              />
              <FormSelect
                name="companies.buildingContractor"
                label="Лицо, осуществляющее строительство"
                items={buildingContractorItems}
              />
              <FormSelect
                name="companies.projectDocumentationContractor"
                label="Лицо, осуществляющее подготовку проектной документации"
                items={projectDocumentationContractorItems}
              />
              <FormSelect
                name="companies.workExecutor"
                label="Лицо, выполнившее работы / конструкции/участки сетей, в отношении которых проведено освидетельствование"
                items={workExecutorItems}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader
              title="Представители"
              subheader="Участники"
            />

            <CardContent>
              <FormSelect
                name="employees.developerRepresentative"
                label="Представитель Застройщика (Технического заказчика, Лица, ответственного за эксплуатацию, Регионального оператора) по вопросам строительного контроля"
                items={developerRepresentativeItems}
              />
              <FormSelect
                name="employees.buildingContractorRepresentative"
                label="Представитель лица, осуществляющего строительство"
                items={buildingContractorRepresentativeItems}
              />
              <FormSelect
                name="employees.constructionManagementRepresentative"
                label="Представитель лица, осуществляющего строительство, по вопросам строительного контроля (специалист по организации строительства)"
                items={constructionManagementRepresentativeItems}
              />
              <FormSelect
                name="employees.projDocContractRepresentative"
                label="Представитель лица, осуществляющего подготовку проектной документации"
                items={projDocContractRepresentativeItems}
              />
              <FormSelect
                name="employees.workExecutorRepresentative"
                label="Представитель лица, выполнившего работы/конструкции/участки сетей"
                items={workExecutorRepresentativeItems}
              />
              <FormSelect
                name="otherEmployees"
                label="Иные представители лиц, участвующих в освидетельствовании"
                items={otherEmployeesItems}
                multiple
              />
            </CardContent>
          </Card>


          <Card>
            <CardContent>
              <FormSelect
                name="items"
                label="Записи ОЖР"
                items={items3List}
                multiple
              />

              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} useFlexGap flexWrap="wrap">
                <Box>
                  <FormInputDate
                    name="data.dateBegin"
                    label="Дата начала работ"
                  />
                </Box>
                <Box>
                  <FormInputDate
                    name="data.dateEnd"
                    label="Дата окончания работ"
                  />
                </Box>
              </Stack>

              <AutocompleteInput
                name="data.allowedWorks"
                label="Работы, выполнение которых разрешается"
                variants={worksItems}
                multiple
              />

              <FormTextarea name="data.additionalInfo" label="Дополнительные сведения" />

            </CardContent>
          </Card>

        </Stack>
      </Box>
    </FormProvider>
  )

}

export {ActOSRForm}
