import { Clear } from '@mui/icons-material'
import {
  FormHelperText,
  FormControl,
  InputProps,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Typography,
  Box,
  Chip,
  ListItemText,
  Checkbox,
  IconButton,
  InputAdornment,
  Stack,
} from '@mui/material'
import React, { FC, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export type SelectItem = {
  value: string
  label?: string
  isGroup?: boolean
  description?: string
}

type IFormInputProps = {
  name: string
  label: string
  items: SelectItem[] | undefined
  hint?: string
  multiple?: boolean
  size?: 'small' | 'medium'
} & InputProps

const chipLabel = (value: string, items: SelectItem[]) => {
  return items.find((i) => i.value === value)?.label || ''
}

const SelectChip = (selected: string[] | string, items: SelectItem[] | undefined) => {
  if (!items) return <></>
  if (!Array.isArray(selected)) return chipLabel(selected, items)
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
      {Array.isArray(selected) &&
        selected.map((value) => <Chip key={value} label={chipLabel(value, items)} />)}
    </Box>
  )
}

const FormInput: FC<IFormInputProps> = ({
  name,
  label,
  hint,
  items,
  multiple = false,
  size,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    getFieldState,
  } = useFormContext()

  const error = getFieldState(name).error
  const errorMessage: string = (error ? error.message : '') as string

  const hintEl = hint ? <FormHelperText>{hint}</FormHelperText> : null

  const groups = useMemo(() => {
    if (!items || !multiple) return {}
    const groups: Record<number, string[]> = {}
    let currentGroupIndex = 0
    groups[currentGroupIndex] = []
    items?.forEach((item, index) => {
      if (item.isGroup) {
        groups[index] = []
        currentGroupIndex = index
      } else {
        groups[currentGroupIndex].push(item.value)
      }
    })
    return groups
  }, [multiple, items])

  const handleClear = () => {
    setValue(name, multiple ? [] : '')
  }

  const handleSelectAllInGroup = (index: number) => {
    if (!multiple || !groups[index]) return

    const selected = getValues(name) as string[]
    const selectedInGroup = selected.filter((s) => groups[index].indexOf(s) !== -1)

    const need = groups[index].filter((i) => selectedInGroup.indexOf(i) === -1)

    if (need.length > 0) {
      setValue(name, [...selected, ...need])
    } else {
      const newSelected = selected.filter((s) => groups[index].indexOf(s) === -1)
      setValue(name, newSelected)
    }
  }

  const isValue = (value: string | string[]) => {
    if (typeof value === 'string') {
      return !!value
    }
    return value.length > 0
  }

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth margin={'dense'} size={size}>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            error={!!error}
            label={label}
            multiple={multiple}
            renderValue={(selected) => SelectChip(selected, items)}
            endAdornment={
              isValue(field.value) && (
                <InputAdornment position="start">
                  <IconButton sx={{ display: '' }} onClick={handleClear}>
                    <Clear />
                  </IconButton>
                </InputAdornment>
              )
            }
          >
            {items &&
              items.map((item, index) => {
                return item.isGroup ? (
                  <ListSubheader
                    key={index}
                    sx={(theme) => ({
                      backgroundColor:
                        theme.palette.mode === 'light'
                          ? theme.palette.grey['200']
                          : theme.palette.grey['900'],
                    })}
                  >
                    <Stack
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent={'space-between'}
                      alignItems={{ md: 'flex-end', xs: 'flex-start' }}
                    >
                      <Box>
                        <Typography sx={{ color: 'text.primary', fontWeight: 'medium' }}>
                          {item.label}
                        </Typography>
                        {item.description && (
                          <Box sx={{ lineHeight: 1 }}>
                            <Typography variant={'caption'} sx={{ color: 'text.secondary' }}>
                              {item.description}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {multiple && (
                        <Typography
                          sx={{
                            color: 'success.light',
                            cursor: 'pointer',
                            '&:hover': { textDecoration: 'underline' },
                          }}
                          onClick={() => handleSelectAllInGroup(index)}
                        >
                          Выбрать все
                        </Typography>
                      )}
                    </Stack>
                  </ListSubheader>
                ) : (
                  <MenuItem key={index} value={item.value}>
                    {multiple && <Checkbox checked={field.value.indexOf(item.value) > -1} />}
                    <ListItemText>
                      <Typography sx={{ color: 'text.primary' }}>
                        {item.label || item.value}
                      </Typography>
                      {item.description && (
                        <Box sx={{ lineHeight: 1 }}>
                          <Typography variant={'caption'} sx={{ color: 'text.secondary' }}>
                            {item.description}
                          </Typography>
                        </Box>
                      )}
                    </ListItemText>
                  </MenuItem>
                )
              })}
          </Select>
          {hintEl}
          <FormHelperText error={!!error}>{errorMessage}</FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default FormInput
