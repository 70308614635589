import { Chip } from '@mui/material'
import * as React from 'react'
import { TDocumentStatus } from '../../types/document'
import { LightChip } from './LightChip'
type TStatus = {
  label: string
  color: 'warning' | 'info' | 'success' | 'error' | 'default' | 'primary' | 'secondary'
}
type TStatuses = Record<TDocumentStatus, TStatus>

const statuses: TStatuses = {
  draft: { label: 'В работе', color: 'warning' },
  agreement: { label: 'Согласование', color: 'info' },
  ready: { label: 'Сформирован', color: 'info' },
  sign_start: { label: 'Подписание', color: 'info' },
  success: { label: 'Подписан', color: 'success' },
  old: { label: 'Устаревший', color: 'error' },
}

export const StatusChip = ({ status, label }: { status: TDocumentStatus; label?: string }) => {
  return (
    <LightChip color={statuses[status].color} label={label || statuses[status].label} size="small" />
  )
}
